// components/YouPage/UserHeader.jsx
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useChannel } from "../../hooks/useChannel";
import CreateChannelModal from "../../components/studio/CreateChannelModal";

function UserHeader() {
  const [showCreateChannel, setShowCreateChannel] = useState(false);
  const { hasChannel, channel, isLoading } = useChannel();
  const { user } = useSelector((state) => state.auth);

  if (isLoading) return null;

  // If user has a channel, show channel info
  if (hasChannel && channel) {
    return (
      <div className="px-8 py-6">
        <div className="flex items-center gap-6">
          <img
            src={channel.avatar}
            alt="Channel avatar"
            className="w-20 h-20 rounded-full"
          />
          <div>
            <h1 className="text-2xl font-bold">{channel.name}</h1>
            <p className="text-gray-500">@{channel.handle}</p>
          </div>
          <div className="ml-auto flex items-center gap-4">
            {/*             <button className="px-3 py-2 hover:bg-gray-100 rounded-full">
              Switch account
            </button>
            <button className="px-3 py-2 hover:bg-gray-100 rounded-full">
              Google Account
            </button> */}
          </div>
        </div>
      </div>
    );
  }

  // If user doesn't have a channel, show basic user info
  return (
    <>
      <div className="px-8 py-6">
        <div className="flex items-center gap-6">
          <img
            src={user?.imageUrl}
            alt="User avatar"
            className="w-20 h-20 rounded-full"
          />
          <div>
            <h1 className="text-2xl font-bold">{user?.username}</h1>
            <p className="text-gray-500">{user?.email}</p>
          </div>
          <button
            onClick={() => setShowCreateChannel(true)}
            className="ml-auto px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-full"
          >
            Create Channel
          </button>
        </div>
      </div>

      {showCreateChannel && (
        <CreateChannelModal
          onClose={() => setShowCreateChannel(false)}
          onSuccess={() => {
            setShowCreateChannel(false);
            window.location.reload(); // Refresh the page to show the new channel
            // Alternatively, you could navigate to the studio:
            // window.location.href = process.env.REACT_APP_STUDIO_URL || "http://localhost:3001";
          }}
        />
      )}
    </>
  );
}

export default UserHeader;
