import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  channelInfo: null,
  channelVideos: [],
  loading: false,
  error: null,
};

const channelSlice = createSlice({
  name: "channel",
  initialState,
  reducers: {
    setChannelInfo: (state, action) => {
      state.channelInfo = action.payload;
    },
    setChannelVideos: (state, action) => {
      state.channelVideos = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setChannelInfo, setChannelVideos, setLoading, setError } =
  channelSlice.actions;

export default channelSlice.reducer;
