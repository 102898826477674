import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useUser } from "@clerk/clerk-react";
import api from "../../config/axios";
import { BiImageAdd } from "react-icons/bi";

function CreateChannelModal({ onClose, onSuccess }) {
  const { user } = useUser();
  const [formData, setFormData] = useState({
    name: user?.fullName || user?.username || "",
    handle: user?.username || "",
    channelImage: null,
    imagePreview: null,
  });

  const createChannel = useMutation({
    mutationFn: async () => {
      const form = new FormData();
      form.append("name", formData.name);
      form.append("handle", formData.handle);
      if (formData.channelImage) {
        form.append("channelImage", formData.channelImage);
      }

      const response = await api.post("/channels/create", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    },
    onSuccess: () => {
      onSuccess?.();
    },
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({
        ...prev,
        channelImage: file,
        imagePreview: URL.createObjectURL(file),
      }));
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">How you'll appear</h2>

        {/* Channel Image Upload */}
        <div className="mb-6">
          <div className="relative w-24 h-24 mx-auto">
            {formData.imagePreview ? (
              <img
                src={formData.imagePreview}
                alt="Channel Preview"
                className="w-24 h-24 rounded-full object-cover"
              />
            ) : (
              <div className="w-24 h-24 rounded-full bg-gray-100 flex items-center justify-center">
                <BiImageAdd className="text-gray-500 text-2xl" />
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            />
          </div>
        </div>

        {/* Name */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            value={formData.name}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, name: e.target.value }))
            }
            className="mt-1 w-full p-2 border rounded bg-gray-50"
          />
        </div>

        {/* Handle */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700">
            Handle
          </label>
          <div className="mt-1 flex">
            <span className="inline-flex items-center px-3 border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
              @
            </span>
            <input
              type="text"
              value={formData.handle}
              onChange={(e) =>
                setFormData((prev) => ({ ...prev, handle: e.target.value }))
              }
              className="flex-1 p-2 border rounded-r"
            />
          </div>
        </div>

        {/* Terms */}
        <p className="text-sm text-gray-600 mb-6">
          By clicking Create Channel you agree to Yivster's Terms of Service.
          Changes made to your name and profile picture are visible only on
          Yivster.
        </p>

        {/* Buttons */}
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded"
          >
            Cancel
          </button>
          <button
            onClick={() => createChannel.mutate()}
            disabled={createChannel.isPending}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            {createChannel.isPending ? "Creating..." : "Create channel"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateChannelModal;
