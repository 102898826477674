import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SignInButton, useAuth } from "@clerk/clerk-react";
import VideoCard from "../../../components/shared/VideoCard";
import {
  setLikedVideos,
  setLoading,
  setError,
} from "../../../redux/slices/videoSlice";
import api from "../../../config/axios";

function LikedVideos() {
  const { isSignedIn } = useAuth();
  const dispatch = useDispatch();
  const { likedVideos, loading, error } = useSelector((state) => state.videos);

  useEffect(() => {
    const fetchLikedVideos = async () => {
      if (!isSignedIn) {
        dispatch(setLoading(false));
        return;
      }

      dispatch(setLoading(true));
      try {
        const response = await api.get("/users/me/liked");
        dispatch(setLikedVideos(response.data));
      } catch (err) {
        dispatch(setError("Failed to fetch liked videos"));
        console.error(err);
      } finally {
        dispatch(setLoading(false));
      }
    };

    fetchLikedVideos();
  }, [dispatch, isSignedIn]);

  if (!isSignedIn) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[70vh] px-4">
        <div className="text-center max-w-md">
          <h2 className="text-2xl font-bold mb-4">
            Sign in to view liked videos
          </h2>
          {/*    <p className="text-gray-600 mb-4">
            Keep track of videos you enjoy and build your personal collection.
          </p> */}
          <p className="text-gray-600 mb-8">
            Sign in to access and keep track of liked videos you enjoy and build
            your personal collection.
          </p>
          <SignInButton mode="modal">
            <button className="px-6 py-3 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors">
              Sign In
            </button>
          </SignInButton>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="px-8 py-6">
        <h1 className="text-2xl font-bold mb-6">Liked Videos</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {[...Array(12)].map((_, i) => (
            <div key={i} className="animate-pulse">
              <div className="bg-gray-200 h-48 rounded-lg mb-2" />
              <div className="bg-gray-200 h-4 w-3/4 mb-2" />
              <div className="bg-gray-200 h-4 w-1/2" />
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="px-8 py-6">
        <h1 className="text-2xl font-bold mb-6">Liked Videos</h1>
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <div className="px-8 py-6">
      <h1 className="text-2xl font-bold mb-6">Liked Videos</h1>
      {likedVideos.length === 0 ? (
        <div className="text-center py-20">
          <p className="text-gray-600">No liked videos yet</p>
          <p className="text-sm text-gray-500 mt-2">
            Videos you like will appear here
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {likedVideos.map((video) => (
            <VideoCard key={video._id} video={video} />
          ))}
        </div>
      )}
    </div>
  );
}

export default LikedVideos;
