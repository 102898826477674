import { Link } from "react-router-dom";
import {
  formatViewCount,
  timeAgo,
  formatDuration,
} from "../../utils/formatters";

function SuggestedVideoCard({ video, watchProgress }) {
  return (
    <Link
      to={`/video/${video._id}`}
      className="flex gap-2 mb-3 hover:bg-gray-100 rounded-lg p-2"
    >
      {/* Thumbnail */}
      <div className="relative flex-shrink-0 w-40 aspect-video">
        <img
          src={video.thumbnailUrl}
          alt={video.title}
          className="w-full h-full object-cover rounded-lg"
          onError={(e) => {
            e.target.src = "https://placehold.co/160x90";
          }}
        />
        {video.duration && (
          <span className="absolute bottom-1 right-1 bg-black bg-opacity-80 text-white text-xs px-1 rounded">
            {formatDuration(video.duration)}
          </span>
        )}

        {/* Watch Progress Bar */}
        {watchProgress && watchProgress.lastPosition > 0 && (
          <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-600/60">
            <div
              className="h-full"
              style={{
                width: `${
                  (watchProgress.lastPosition / video.duration) * 100
                }%`,
                backgroundColor: "#9147ff",
              }}
            />
          </div>
        )}
      </div>

      {/* Video Info */}
      <div className="flex-1">
        <h3 className="font-medium text-sm line-clamp-2">{video.title}</h3>
        <p className="text-gray-600 text-sm mt-1">{video.channelId?.name}</p>
        <div className="text-gray-600 text-xs">
          <span>{formatViewCount(video.views)} views</span>
          <span className="mx-1">•</span>
          <span>{timeAgo(video.createdAt)}</span>
        </div>
      </div>
    </Link>
  );
}

export default SuggestedVideoCard;
