// In ChannelCard.jsx for search
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatNumber } from "../../utils/formatters";
import { BiCheck } from "react-icons/bi";
import SubscribeButton from "../shared/SubscribeButton";
import { useDispatch, useSelector } from "react-redux";
import { setChannelInfo } from "../../redux/slices/channelSlice";
import { useAuth } from "@clerk/clerk-react";
import api from "../../config/axios";
import { SignInButton } from "@clerk/clerk-react";

function ChannelCard({ channel, onChannelUpdate }) {
  const dispatch = useDispatch();
  const [showSignInPrompt, setShowSignInPrompt] = useState(false);
  const { isSignedIn } = useAuth();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const subscribedChannels = useSelector(
    (state) => state.subscriptions.subscribedChannels
  );

  console.log(channel.avatar);

  return (
    <Link
      to={`/channel/@${channel.handle}`}
      className="flex items-start gap-4 max-w-[850px] p-4 hover:bg-gray-100/50 rounded-xl"
    >
      <img
        src={channel.ownerId?.imageUrl} // Update this line
        alt={channel.name}
        className="w-[136px] h-[136px] rounded-full object-cover"
      />

      <div className="flex-1">
        <div className="flex items-center gap-2">
          <h2 className="text-lg font-medium">{channel.name}</h2>
          {channel.isVerified && <span className="text-gray-400">✓</span>}
        </div>

        <div className="text-sm text-gray-600 mt-1">
          <span>@{channel.handle}</span>
          <span className="mx-2">•</span>
          <span>{formatNumber(channel.subscriberCount)} subscribers</span>
          {channel.recentVideos?.length > 0 && (
            <>
              <span className="mx-2">•</span>
              <span>{channel.recentVideos.length} videos</span>
            </>
          )}
        </div>

        {channel.description && (
          <p className="text-sm text-gray-600 mt-2 line-clamp-2">
            {channel.description}
          </p>
        )}
        <div className="mt-4" onClick={(e) => e.preventDefault()}>
          <SubscribeButton
            channelId={channel._id}
            channelHandle={channel.handle}
            onSubscriptionChange={(result) => {
              if (result === "signin-required") {
                setShowSignInPrompt(true);
              } else {
                onChannelUpdate({
                  ...channel,
                  subscriberCount: result.subscriberCount,
                });
              }
            }}
          />
        </div>

        {showSignInPrompt && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg max-w-sm w-full mx-4">
              <h3 className="text-lg font-bold mb-2">Sign in to subscribe</h3>
              <p className="text-gray-600 mb-4">
                Sign in to subscribe to this channel and get notified about new
                videos!
              </p>
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => setShowSignInPrompt(false)}
                  className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
                >
                  Cancel
                </button>
                <SignInButton mode="modal">
                  <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                    Sign In
                  </button>
                </SignInButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
}

export default ChannelCard;
