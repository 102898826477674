import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SignInButton, useAuth } from "@clerk/clerk-react";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import AboutModal from "../../components/channel/About";
import {
  setChannelInfo,
  setChannelVideos,
  setLoading,
  setError,
} from "../../redux/slices/channelSlice";
import { setSubscribedChannels } from "../../redux/slices/subscriptionSlice";
import ChannelVideoCard from "../../components/channel/ChannelVideoCard";
import ChannelPageSkeleton from "../../components/channel/ChannelPageSkeleton";
import SubscribeButton from "../../components/shared/SubscribeButton";
import { getWatchProgress } from "../../utils/videoProgress";
import api from "../../config/axios";
import { MdVideoLibrary, MdInfo } from "react-icons/md";

const ChannelPage = () => {
  const { handle } = useParams();
  const dispatch = useDispatch();
  const { channelInfo, channelVideos, loading, error } = useSelector(
    (state) => state.channel
  );

  const { isSignedIn } = useAuth(); // Replace isAuthenticated with this

  const { isAuthenticated } = useSelector((state) => state.auth);
  const subscribedChannels = useSelector(
    (state) => state.subscriptions.subscribedChannels
  );

  const [showSignInPrompt, setShowSignInPrompt] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("videos");
  const [videoProgress, setVideoProgress] = useState({}); // Add this

  const tabs = [
    { id: "videos", label: "Videos", icon: <MdVideoLibrary /> },
    { id: "about", label: "About", icon: <MdInfo /> },
  ];

  // Use the custom hook to set the document title
  useDocumentTitle(
    channelInfo ? `${channelInfo.handle} - Yivster` : "Loading..."
  );

  useEffect(() => {
    const fetchChannelData = async () => {
      dispatch(setLoading(true));
      try {
        const cleanHandle = handle.startsWith("@")
          ? handle.substring(1)
          : handle;

        // Fetch basic channel and video data with populated ownerId
        const [channelResponse, videosResponse] = await Promise.all([
          api.get(`/channels/@${cleanHandle}`),
          api.get(`/channels/@${cleanHandle}/videos`),
        ]);

        // Create base channel data object that all users will see
        const baseChannelData = {
          name: channelResponse.data.name,
          handle:
            channelResponse.data.displayHandle || channelResponse.data.handle,
          // Use the virtual avatar field which comes from ownerId.imageUrl
          avatar:
            channelResponse.data.ownerId?.imageUrl ||
            `https://ui-avatars.com/api/?name=${encodeURIComponent(
              channelResponse.data.name
            )}`,
          banner: channelResponse.data.banner,
          subscribers: channelResponse.data.subscriberCount,
          _id: channelResponse.data._id,
          description:
            channelResponse.data.description || "No description available.",
          joinedDate: new Date(
            channelResponse.data.createdAt
          ).toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          }),
          totalViews: videosResponse.data.reduce(
            (acc, video) => acc + (video.views || 0),
            0
          ),
          socialLinks: channelResponse.data.socialLinks,
          isSubscribed: false, // Default to false
        };

        // If user is authenticated, fetch and add subscription data
        if (isSignedIn && isAuthenticated) {
          try {
            const userData = await api.get("/users/me");
            const userSubscriptions = userData.data.subscribedChannels || [];
            dispatch(setSubscribedChannels(userSubscriptions));

            // Update isSubscribed based on user's subscription status
            baseChannelData.isSubscribed = userSubscriptions.includes(
              channelResponse.data._id
            );
          } catch (err) {
            console.error("Error fetching user data:", err);
            // Continue with base channel data if user data fetch fails
          }
        } else {
          // Clear subscriptions for non-authenticated users
          dispatch(setSubscribedChannels([]));
        }

        // Set channel info and videos for all users
        dispatch(setChannelInfo(baseChannelData));
        dispatch(setChannelVideos(videosResponse.data));

        // Get watch progress for videos
        const progress = await getWatchProgress(
          videosResponse.data,
          isSignedIn
        );
        setVideoProgress(progress);
      } catch (err) {
        console.error("Error fetching channel data:", err);
        dispatch(
          setError(
            err.response?.data?.message || "Failed to fetch channel data"
          )
        );
      } finally {
        dispatch(setLoading(false));
      }
    };

    if (handle) {
      fetchChannelData();
    }
  }, [handle, dispatch, isSignedIn, isAuthenticated]);

  if (loading) {
    return <ChannelPageSkeleton />;
  }

  if (error) return <div className="p-4 text-red-500">Error: {error}</div>;
  if (!channelInfo) return <div className="p-4">Channel not found</div>;

  //console.log(user);

  return (
    // Update the main container classes
    <div className="min-h-screen bg-white">
      {/* Banner - make it proper height */}
      <div className="max-w-[1850px] mx-auto px-6 pt-6">
        <div className="relative w-full h-[200px]">
          <img
            src={channelInfo.banner}
            alt=""
            className="w-full h-full object-cover rounded-xl" // Added rounded corners
          />
        </div>
      </div>

      {/* Channel Info Section - updated layout */}
      <div className="max-w-[1850px] mx-auto px-6">
        {/* Profile info container */}
        <div className="flex flex-col md:flex-row gap-6 mt-6">
          {/* Avatar */}
          <div>
            <img
              src={channelInfo.avatar}
              alt={channelInfo.name}
              className="w-[128px] h-[128px] rounded-full"
            />
          </div>

          {/* Channel info */}
          <div className="flex-1">
            {/* Channel name and stats */}
            <div className="mb-2">
              <h1 className="text-xl font-medium">{channelInfo.name}</h1>
              <div className="flex items-center gap-1 text-sm text-gray-600 mt-1">
                <span>@{channelInfo.handle}</span>
                <span className="text-[6px] mx-1">●</span>
                <span>{channelInfo.subscribers} subscribers</span>
                <span className="text-[6px] mx-1">●</span>
                <span>{channelVideos.length} videos</span>
              </div>
            </div>
            {/* Description with more button */}
            <div className="text-sm text-gray-600 mt-2">
              <div className="max-w-[600px]">
                {/* Limit description to roughly 2 lines */}
                <p className="line-clamp-2">{channelInfo.description}</p>
              </div>

              {/* Social Links Preview */}
              <div className="flex items-center gap-2 mt-1">
                {channelInfo.socialLinks &&
                  Object.entries(channelInfo.socialLinks)
                    .slice(0, 2)
                    .map(([platform, url], index) => (
                      <a
                        key={platform}
                        href={url}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        {platform}
                      </a>
                    ))}

                <button
                  onClick={() => setIsModalOpen(true)}
                  className="text-sm text-gray-600 hover:text-gray-900"
                >
                  ...more
                </button>
              </div>
            </div>
            {/* Subscribe button */}
            <SubscribeButton
              channelId={channelInfo._id}
              channelHandle={channelInfo.handle}
              onSubscriptionChange={(result) => {
                if (result === "signin-required") {
                  setShowSignInPrompt(true);
                  return;
                }

                // First update subscriptions state
                const newSubscribedChannels = result.isSubscribed
                  ? [...subscribedChannels, channelInfo._id]
                  : subscribedChannels.filter((id) => id !== channelInfo._id);

                dispatch(setSubscribedChannels(newSubscribedChannels));

                // Then update channel info
                dispatch(
                  setChannelInfo({
                    ...channelInfo,
                    subscriberCount: result.subscriberCount,
                    isSubscribed: result.isSubscribed,
                  })
                );
              }}
            />
          </div>
        </div>

        {/* Navigation - more subtle design */}
        <div className="max-w-[1850px] mx-auto px-6">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex gap-2">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`flex items-center gap-2 px-6 py-3 
            ${
              activeTab === tab.id
                ? "border-b-2 border-black text-black font-medium"
                : "text-gray-600 hover:text-black"
            }
          `}
                >
                  {tab.icon}
                  {tab.label}
                </button>
              ))}
            </nav>
          </div>
        </div>

        {/* Content section */}
        <div className="py-6">
          {activeTab === "videos" ? (
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              {channelVideos.map((video) => (
                <ChannelVideoCard
                  key={video._id}
                  video={video}
                  watchProgress={videoProgress[video._id]}
                />
              ))}
            </div>
          ) : (
            <div className="max-w-3xl">
              <h2 className="font-medium mb-4">Description</h2>
              <p className="text-gray-700 whitespace-pre-line mb-6">
                {channelInfo.description}
              </p>
              <div className="text-sm text-gray-600">
                <p>Joined {channelInfo.joinedDate}</p>
                <p>{channelInfo.totalViews} total views</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Modals */}
      <AboutModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        channelInfo={channelInfo}
        channelVideos={channelVideos}
      />

      {/* Sign In Prompt Modal */}
      {showSignInPrompt && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg max-w-sm w-full mx-4">
            <h3 className="text-lg font-bold mb-2 dark:text-white">
              Sign in to subscribe
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Sign in to subscribe to this channel and get notified about new
              videos!
            </p>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowSignInPrompt(false)}
                className="px-4 py-2 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
              >
                Cancel
              </button>
              <SignInButton mode="modal">
                <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                  Sign In
                </button>
              </SignInButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChannelPage;
