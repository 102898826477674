import React, { useState } from "react";
import { X } from "lucide-react";
import { useToast } from "../shared/Toast";
import api from "../../config/axios";
import { SignInButton, useAuth } from "@clerk/clerk-react";

const CommentReportModal = ({
  isOpen,
  onClose,
  comment,
  onSuccess,
  onError,
}) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showToast, ToastComponent } = useToast();
  const { isSignedIn } = useAuth();

  const reportReasons = [
    {
      value: "Sexual content",
      label: "Sexual content",
    },
    {
      value: "Violent or repulsive content",
      label: "Violent or repulsive content",
    },
    {
      value: "Hateful or abusive content",
      label: "Hateful or abusive content",
    },
    {
      value: "Harassment or bullying",
      label: "Harassment or bullying",
    },
    {
      value: "Harmful or dangerous acts",
      label: "Harmful or dangerous acts",
    },
    {
      value: "Misinformation",
      label: "Misinformation",
    },
  ];

  // In CommentReportModal.jsx, modify the handleSubmit function:
  // In CommentReportModal.jsx
  const handleSubmit = async () => {
    if (!isSignedIn) {
      return;
    }

    if (!selectedReason) {
      showToast("Please select a reason for reporting", "error");
      return;
    }

    try {
      setIsSubmitting(true);
      await api.post(`/comments/${comment._id}/report`, {
        reason: selectedReason,
      });

      showToast("Report submitted successfully", "success");

      setTimeout(() => {
        setSelectedReason("");
        onClose();
      }, 500);
    } catch (error) {
      showToast(
        error.response?.data?.message || "Failed to submit report",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/60 flex items-center justify-center z-[1000]">
      <div className="bg-white w-full max-w-[400px] mx-4 rounded-xl">
        <div className="flex flex-col px-6 py-4">
          <div className="flex justify-between items-start mb-4">
            <div>
              <h2 className="text-lg font-medium">Report</h2>
              <p className="text-sm text-gray-500 mt-1">What's going on?</p>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600 p-1"
            >
              <X size={20} />
            </button>
          </div>

          <p className="text-sm text-gray-500 mb-4">
            We'll check for all Community Guidelines, so don't worry about
            making the perfect choice.
          </p>

          <div className="space-y-2">
            {reportReasons.map((reason) => (
              <label
                key={reason.value}
                className="flex items-center py-2 px-2 rounded-lg hover:bg-gray-50 cursor-pointer transition-colors"
              >
                <input
                  type="radio"
                  name="reportReason"
                  value={reason.value}
                  checked={selectedReason === reason.value}
                  onChange={(e) => setSelectedReason(e.target.value)}
                  className="appearance-none w-4 h-4 rounded-full border-2 border-gray-300 checked:border-[#B94EEF] checked:border-[5px] transition-all duration-200 cursor-pointer focus:outline-none focus:ring-2 focus:ring-[#B94EEF] focus:ring-offset-2"
                />
                <span className="ml-3 text-sm text-gray-700">
                  {reason.label}
                </span>
              </label>
            ))}
          </div>

          <button
            onClick={handleSubmit}
            disabled={!selectedReason || isSubmitting}
            className="mt-6 w-full py-2 text-sm font-medium text-white bg-[#B94EEF] rounded-full hover:bg-[#B94EEF]/90 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Report
          </button>
        </div>
      </div>
      {ToastComponent}
    </div>
  );
};

export default CommentReportModal;
