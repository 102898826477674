// subscriptionSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscribedChannels: [], // Array of channel IDs the user is subscribed to
};

const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setSubscribedChannels: (state, action) => {
      state.subscribedChannels = action.payload;
    },
    addSubscription: (state, action) => {
      state.subscribedChannels.push(action.payload);
    },
    removeSubscription: (state, action) => {
      state.subscribedChannels = state.subscribedChannels.filter(
        (id) => id !== action.payload
      );
    },
  },
});

export const { setSubscribedChannels, addSubscription, removeSubscription } =
  subscriptionSlice.actions;
export default subscriptionSlice.reducer;
