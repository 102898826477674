import React from "react";
import { Link } from "react-router-dom";

const TermsPage = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header */}
      <div className="mb-12">
        <h1 className="text-[#4cc9f0] text-5xl font-bold mb-4">
          Terms of Service
        </h1>
        <div className="text-black-400">Date Modified: [December 19, 2024]</div>
      </div>

      {/* Content Sections */}
      <div className="space-y-12">
        {/* Intellectual Property Rights */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            INTELLECTUAL PROPERTY RIGHTS
          </h1>
          <p className="mb-4">
            Unless otherwise indicated, the platform is our proprietary property
            and all source code, databases, functionality, software, website
            designs, app design, audio, video, text, photographs, and graphics
            on the platform (collectively, the “Content”) and the trademarks,
            service marks, and logos contained therein (the “Marks”) are owned
            or controlled by us or licensed to us, and are protected by
            copyright and trademark laws and various other intellectual property
            rights and unfair competition laws of the United States, foreign
            jurisdictions, and international conventions. The Content and the
            Marks are provided on the platform “AS IS” for your information and
            personal use only. Except as expressly provided in these Terms and
            Conditions, no part of the platform and no Content or Marks may be
            copied, reproduced, aggregated, republished, uploaded, posted,
            publicly displayed, encoded, translated, transmitted, distributed,
            sold, licensed, or otherwise exploited for any commercial purpose
            whatsoever, without our express prior written permission. provided
            that you are eligible to use the platform, you are granted a limited
            license to access and use the platform and to download or print a
            copy of any portion of the Content to which you have properly gained
            access solely for your personal, non-commercial use. We reserve all
            rights not expressly granted to you in and to the platform, the
            Content and the Marks.
          </p>
        </section>

        {/* User Representations */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            USER REPRESENTATIONS
          </h1>
          <p className="mb-4">
            By using the platform, you represent and warrant that: (1)all
            registration information you submit will be true, accurate, current,
            and complete. (2) you will maintain the accuracy of such information
            and promptly update such registration information as necessary;
            (3)you have the legal capacity and you agree to comply with these
            Terms and Conditions; (4)If you are under the age of 13. You must
            have received parental permission to use the platform. (5)you will
            not access the platform through automated or non-human means,
            whether through a bot, script, or otherwise (6)you will not use the
            platform for any illegal or unauthorized purpose; (7)your use of the
            platform will not violate any applicable law or regulation. If you
            provide any information that is untrue, inaccurate, not current, or
            incomplete, we have the right to suspend or terminate your account
            and refuse any and all current or future use of the platform (or any
            portion thereof).
          </p>
        </section>

        {/* User Registration */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            USER REGISTRATION
          </h1>
          <p className="mb-4">
            You may be required to register with the platform. You agree to keep
            your password confidential and will be responsible for all use of
            your account and password. We reserve the right to remove, reclaim,
            or change a username you select if we determine, in our sole
            discretion, that such username is inappropriate, obscene, or
            otherwise objectionable. pROHIBITED ACTIVITIES You may not access or
            use the platform for any purpose other than that for which we make
            the platform available. The platform may not be used in connection
            with any commercial endeavors except those that are specifically
            endorsed or approved by us. As a user of the platform, you agree not
            to: 1.systematically retrieve data or other content from the
            platform to create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us. 2.make any unauthorized use of the platform, including
            collecting usernames and/or email addresses of users by electronic
            or other means for the purpose of sending unsolicited email, or
            creating user accounts by automated means or under false pretenses.
            3.use the platform to advertise or offer to sell goods and services.
            4.circumvent, disable, or otherwise interfere with security-related
            features of the platform, including features that prevent or
            restrict the use or copying of any Content or enforce limitations on
            the use of the platform and/or the Content contained therein.
            5.engage in unauthorized framing of or linking to the platform.
            6.trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords; 7.make improper use of our support services or submit
            false reports of abuse or misconduct. 8.engage in any automated use
            of the system, such as using scripts to send comments or messages,
            or using any data mining, robots, or similar data gathering and
            extraction tools. 9.interfere with, disrupt, or create an undue
            burden on the platform or the networks or services connected to the
            platform. 10.attempt to impersonate another user or person or use
            the username of another user. 11.use any information obtained from
            the platform in order to harass, abuse, or harm another person.
            12.use the platform as part of any effort to compete with us or
            otherwise use the platform and/or the Content for any
            revenue-generating endeavor or commercial enterprise. 13.decipher,
            decompile, disassemble, or reverse engineer any of the software
            comprising or in any way making up a part of the platform.
            14.attempt to bypass any measures of the platform designed to
            prevent or restrict access to the platform, or any portion of the
            platform. 15.harass, annoy, intimidate, or threaten any of our
            employees or agents engaged in providing any portion of the platform
            to you. 16.delete the copyright or other proprietary rights notice
            from any Content. 17.upload or transmit (or attempt to upload or to
            transmit) viruses, Trojan horses, or other material, including
            excessive use of capital letters and spamming (continuous posting of
            repetitive text), that interferes with any party’s uninterrupted use
            and enjoyment of the platform or modifies, impairs, disrupts,
            alters, or interferes with the use, features, functions, operation,
            or maintenance of the platform. 18.upload or transmit (or attempt to
            upload or to transmit) any material that acts as a passive or active
            information collection or transmission mechanism, including without
            limitation, clear graphics interchange formats (“gifs”), 1×1 pixels,
            web bugs, cookies, or other similar devices (sometimes referred to
            as “spyware” or “passive collection mechanisms” or “pcms”).
            19.except as may be the result of standard search engine or Internet
            browser usage, use, launch, develop, or distribute any automated
            system, including without limitation, any spider, robot, cheat
            utility, scraper, or offline reader that accesses the platform, or
            using or launching any unauthorized script or other software.
            20.disparage, tarnish, or otherwise harm, in our opinion, us and/or
            the platform. 21.use the platform in a manner inconsistent with any
            applicable laws or regulations.
          </p>
        </section>

        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            USER GENERATED CONTRIBUTIONS
          </h1>
          <p className="mb-4">
            The platform may invite you to contribute to, content, and other
            functionality, and may provide you with the opportunity to create,
            submit, post, display, transmit, perform, publish, distribute, or
            broadcast content and materials to us or on the platform, including
            but not limited to text, writings, video, audio, photographs,
            graphics, comments, suggestions, or personal information or other
            material (collectively, “Contributions”). Contributions may be
            viewable by other users of the platform and through third-party
            websites. As such, any Contributions you transmit may be treated as
            non-confidential and non-proprietary. When you create or make
            available any Contributions, you thereby represent and warrant that:
            1.the creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party. 2.you are the creator
            and owner of or have the necessary licenses, rights, consents,
            releases, and permissions to use and to authorize us, the platform,
            and other users of the platform to use your Contributions in any
            manner contemplated by the platform and these Terms and Conditions.
            3.you have the written consent, release, and/or permission of each
            and every identifiable individual person in your Contributions to
            use the name or likeness of each and every such identifiable
            individual person to enable inclusion and use of your Contributions
            in any manner contemplated by the platform and these Terms and
            Conditions. 4.your Contributions are not false, inaccurate, or
            misleading. 5.your Contributions are not unsolicited or unauthorized
            advertising, promotional materials, pyramid schemes, chain letters,
            spam, mass mailings, or other forms of solicitation. 6.your
            Contributions are not obscene, lewd, lascivious, filthy, violent,
            harassing, libelous, slanderous, or otherwise objectionable (as
            determined by us). 7.your Contributions do not ridicule, mock,
            disparage, intimidate, or abuse anyone. 8.your Contributions do not
            advocate the violent overthrow of any government or incite,
            encourage, or threaten physical harm against another. 9.your
            Contributions do not violate any applicable law, regulation, or
            rule. 10.your Contributions do not violate the privacy or publicity
            rights of any third party. 11.your Contributions do not contain any
            material that solicits personal information from anyone or exploits
            anyone in a sexual or violent manner. 12.your Contributions do not
            violate any federal or state law concerning child pornography, or
            otherwise intended to protect the health or well-being of minors;
            13.your Contributions do not include any offensive comments that are
            connected to race, national origin, gender, sexual preference, or
            physical handicap. 14.your Contributions do not otherwise violate,
            or link to material that violates, any provision of these Terms and
            Conditions, or any applicable law or regulation. Any use of the
            platform in violation of the foregoing violates these Terms and
            Conditions and may result in, among other things, termination or
            suspension of your rights to use the platform.
          </p>
        </section>

        {/* CONTRIBUTION LICENSE */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            CONTRIBUTION LICENSE
          </h1>
          <p className="mb-4">
            By posting your Contributions to any part of the platform or making
            Contributions accessible to the platform by linking your account
            from the platform to any of your social networking accounts], you
            automatically grant, and you represent and warrant that you have the
            right to grant, to us an unrestricted, unlimited, irrevocable,
            perpetual, non-exclusive, transferable, royalty-free,fully-paid,
            worldwide right, and license to host, use, copy, reproduce,
            disclose, sell, resell, publish, broadcast, retitle, archive, store,
            cache, publicly perform, publicly display, reformat, translate,
            transmit, excerpt (in whole or in part), and distribute such
            Contributions (including, without limitation, your image and voice)
            for any purpose, commercial, advertising, or otherwise, and to
            prepare derivative works of, or incorporate into other works, such
            Contributions, and grant and authorize sublicenses of the foregoing.
            The use and distribution may occur in any media formats and through
            any media channels. This license will apply to any form, media, or
            technology now known or hereafter developed, and includes our use of
            your name, company name, and franchise name, as applicable, and any
            of the trademarks, service marks, trade names, logos, and personal
            and commercial images you provide. You waive all moral rights in
            your Contributions, and you warrant that moral rights have not
            otherwise been asserted in your Contributions. We do not assert any
            ownership over your Contributions. You retain full ownership of all
            of your Contributions and any intellectual property rights or other
            proprietary rights associated with your Contributions. We are not
            liable for any statements or representations in your Contributions
            provided by you in any area on the platform. You are solely
            responsible for your Contributions to the platform and you expressly
            agree to exonerate us from any and all responsibility and to refrain
            from any legal action against us regarding your Contributions. We
            have the right, in our sole and absolute discretion, (1) to edit,
            redact, or otherwise change any Contributions; (2) to re-categorize
            any Contributions to place them in more appropriate locations on the
            platform; and (3) to pre-screen or delete any Contributions at any
            time and for any reason, without notice. We have no obligation to
            monitor your Contributions.
          </p>
        </section>

        {/* PLATFORM APPLICATION */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            PLATFORM MANAGEMENT
          </h1>
          <p className="mb-4">
            LICENSE Use License If you access the platform via a mobile
            application or website, then we grant you a limited right to install
            and use the mobile application on wireless electronic devices owned
            or controlled by you, and to access and use the mobile application &
            site on such devices strictly in accordance with the terms and
            conditions of this platform application license contained in these
            Terms and Conditions. You shall not: (1)decompile, reverse engineer,
            disassemble, attempt to derive the source code of, or decrypt the
            application; (2)make any modification, adaptation, improvement,
            enhancement, translation, or derivative work from the application;
            (3)violate any applicable laws, rules, or regulations in connection
            with your access or use of the application; (4)remove, alter, or
            obscure any proprietary notice (including any notice of copyright or
            trademark) posted by us or the licensors of the application; (5)use
            the application for any revenue generating endeavor, commercial
            enterprise, or other purpose for which it is not designed or
            intended; (6)make the application available over a network or other
            environment permitting access or use by multiple devices or users at
            the same time; (7)use the application for creating a product,
            service, or software that is, directly or indirectly, competitive
            with or in any way a substitute for the application; (8)use the
            application to send automated queries to any website or to send any
            unsolicited commercial e-mail; (9)use any proprietary information or
            any of our interfaces or our other intellectual property in the
            design, development, manufacture, licensing, or distribution of any
            applications, accessories, or devices for use with the application.
            SUBMISSIONS You acknowledge and agree that any questions, comments,
            suggestions, ideas, feedback, or other information regarding the
            platform (“Submissions”) provided by you to us are non-confidential
            and shall become our sole property. We shall own exclusive rights,
            including all intellectual property rights, and shall be entitled to
            the unrestricted use and dissemination of these Submissions for any
            lawful purpose, commercial or otherwise, without acknowledgment or
            compensation to you. You hereby waive all moral rights to any such
            Submissions, and you hereby warrant that any such Submissions are
            original with you or that you have the right to submit such
            Submissions. You agree there shall be no recourse against us for any
            alleged or actual infringement or misappropriation of any
            proprietary right in your Submissions.
          </p>
        </section>

        {/* PAYMENTS */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">PAYMENTS</h1>
          <p className="mb-4">
            Yivster uses Stripe to process payments. Unless otherwise agreed by
            the parties in writing, if applicable, Company shall remit payments
            due to you hereunder no later than thirty (30) days after the end of
            each calendar month in which the applicable fees are received.
            Payouts are generally sent around the 1st of every month. With
            Stripe if you're recieving your first payout it can take 7-14
            business days for risk mitigation purposes Through tipping creators
            recieve 80% of their tips while we the playform recieve the
            remaining 20%. We accept various payment methods through Stripe,
            including Mastercard, Visa, and American Express. By using the
            Service, you agree to be bound by Stripe’s Services Agreement
            available at https://stripe.com/us/legal. Company reserves the right
            to withhold payment or charge back to your User Account any amounts
            otherwise due to us under these Terms, or amounts due to any breach
            of these Terms by you, pending Company’s reasonable investigation of
            such breach. Company also reserves the right to withhold payment or
            charge back to your User Account any amounts subject to dispute,
            such as in the case of credit card charge backs, pending successful
            resolution of the dispute. To ensure proper payment, you are solely
            responsible for providing and maintaining accurate contact and
            payment information associated with your User Account, which
            includes without limitation applicable tax information. If we
            believe that we are obligated to obtain tax information and you do
            not provide this information to us after we have requested it, we
            may withhold your payments until you provide this information or
            otherwise satisfy us that you are not a person or entity from whom
            we are required to obtain tax information. Any third-party fees
            related to returned or cancelled payments due to a contact or
            payment information error or omission may be deducted from the newly
            issued payment. You agree to pay all applicable taxes or charges
            imposed by any government entity in connection with your
            participation in the Service.
          </p>
        </section>

        {/* THIRD-PARTY WEBSITES AND CONTENT */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            THIRD-PARTY WEBSITES AND CONTENT
          </h1>
          <p className="mb-4">
            The platform may contain (or you may be sent via the platform) links
            to other websites (“Third-party Websites”) as well as articles,
            photographs, text, graphics, pictures, designs, music, sound, video,
            information, applications, software, and other content or items
            belonging to or originating from third parties (“Third-party
            Content”). Such Third-party Websites and Third-party Content are not
            investigated, monitored, or checked for accuracy, appropriateness,
            or completeness by us, and we are not responsible for any
            Third-party Websites accessed through the platform or any
            Third-party Content posted on, available through, or installed from
            the platform, including the content, accuracy, offensiveness,
            opinions, reliability, privacy practices, or other policies of or
            contained in the Third-party Websites or the Third-party Content.
            Inclusion of, linking to, or permitting the use or installation of
            any Third-party Websites or any Third-party Content does not imply
            approval or endorsement thereof by us. If you decide to leave the
            platform and access the Third-party Websites or to use or install
            any Third-party Content, you do so at your own risk, and you should
            be aware these Terms and Conditions no longer govern. You should
            review the applicable terms and policies, including privacy and data
            gathering practices, of any website to which you navigate from the
            platform or relating to any applications you use or install from the
            platform. Any purchases you make through Third-party Websites will
            be through other websites and from other companies, and we take no
            responsibility whatsoever in relation to such purchases which are
            exclusively between you and the applicable third party. You agree
            and acknowledge that we do not endorse the products or services
            offered on Third-party Websites and you shall hold us harmless from
            any harm caused by your purchase of such products or services.
            Additionally, you shall hold us harmless from any losses sustained
            by you or harm caused to you relating to or resulting in any way
            from any Third-party Content or any contact with Third-party
            Websites.
          </p>
        </section>

        {/* ADVERTISERS */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            ADVERTISERS
          </h1>
          <p className="mb-4">
            e allow advertisers to display their advertisements and other
            information in certain areas of the platform, such as sidebar
            advertisements or banner advertisements. If you are an advertiser,
            you shall take full responsibility for any advertisements you place
            on the platform and any services provided on the platform or
            products sold through those advertisements. Further, as an
            advertiser, you warrant and represent that you possess all rights
            and authority to place advertisements on the platform, including,
            but not limited to, intellectual property rights, publicity rights,
            and contractual rights. As an advertiser, you agree that such
            advertisements are subject to our Digital Millennium Copyright Act
            (“DMCA”) Notice and policy provisions as described below, and you
            understand and agree there will be no refund or other compensation
            for DMCA takedown-related issues. We simply provide the space to
            place such advertisements, and we have no other relationship with
            advertisers.{" "}
          </p>
        </section>

        {/* PLATFORM MANAGEMENT */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            PLATFORM MANAGEMENT
          </h1>
          <p className="mb-4">
            We reserve the right, but not the obligation, to: (1)monitor the
            platform for violations of these Terms and Conditions; (2)take
            appropriate legal action against anyone who, in our sole discretion,
            violates the law or these Terms and Conditions, including without
            limitation, reporting such user to law enforcement authorities;
            (3)in our sole discretion and without limitation, refuse, restrict
            access to, limit the availability of, or disable (to the extent
            technologically feasible) any of your Contributions or any portion
            thereof; (4)in our sole discretion and without limitation, notice,
            or liability, to remove from the platform or otherwise disable all
            files and content that are excessive in size or are in any way
            burdensome to our systems; (5)otherwise manage the platform in a
            manner designed to protect our rights and property and to facilitate
            the proper functioning of the platform.
          </p>
        </section>

        {/* PRIVACY POLICY */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            PRIVACY POLICY
          </h1>
          <p className="mb-4">
            We care about data privacy and security. please review our privacy
            policy {/* (CLICK HERE) */}. By using the platform, you agree to be
            bound by our privacy policy, which is incorporated into these Terms
            and Conditions. please be advised the platform is hosted in the
            United States. If you access the platform from the European Union,
            Asia, or any other region of the world with laws or other
            requirements governing personal data collection, use, or disclosure
            that differ from applicable laws in the United States, then through
            your continued use of the platform, you are transferring your data
            to the United States, and you expressly consent to have your data
            transferred to and processed in the United States. Further, we do
            not knowingly accept, request, or solicit information from children
            or knowingly market to children. Therefore, in accordance with the
            U.S. Children’s Online privacy protection Act, if we receive actual
            knowledge that anyone under the age of 13 has provided personal
            information to us without the requisite and verifiable parental
            consent, we will delete that information from the platform as
            quickly as is reasonably practical.{" "}
          </p>
        </section>

        {/* DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY
          </h1>
          <p className="mb-4">
            Notifications We respect the intellectual property rights of others.
            If you believe that any material available on or through the
            platform infringes upon any copyright you own or control, please
            immediately notify our Designated Copyright Agent using the contact
            information provided below (a “Notification”). A copy of your
            Notification will be sent to the person who posted or stored the
            material addressed in the Notification. please be advised that
            pursuant to federal law you may be held liable for damages if you
            make material misrepresentations in a Notification. Thus, if you are
            not sure that material located on or linked to by the platform
            infringes your copyright, you should consider first contacting an
            attorney. All Notifications should meet the requirements of DMCA 17
            U.S.C. § 512(c)(3) and include the following information: (1)a
            physical or electronic signature of a person authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed; (2)identification of the copyrighted work claimed to have
            been infringed, or, if multiple copyrighted works on the platform
            are covered by the Notification, a representative list of such works
            on the platform; (3)identification of the material that is claimed
            to be infringing or to be the subject of infringing activity and
            that is to be removed or access to which is to be disabled, and
            information reasonably sufficient to permit us to locate the
            material; (4)information reasonably sufficient to permit us to
            contact the complaining party, such as an address, telephone number,
            and, if available, an email address at which the complaining party
            may be contacted; (5)a statement that the complaining party has a
            good faith belief that use of the material in the manner complained
            of is not authorized by the copyright owner, its agent, or the law;
            (6)a statement that the information in the notification is accurate,
            and under penalty of perjury, that the complaining party is
            authorized to act on behalf of the owner of an exclusive right that
            is allegedly infringed upon. Counter Notification If you believe
            your own copyrighted material has been removed from the platform as
            a result of a mistake or misidentification, you may submit a written
            counter notification to us using the contact information provided
            below (a “Counter Notification”). [Yivster,LLC]
            [Corporate@yivster.com] To be an effective Counter Notification
            under the DMCA, your Counter Notification must include substantially
            the following: (1)identification of the material that has been
            removed or disabled and the location at which the material appeared
            before it was removed or disabled; (2)a statement that you consent
            to the jurisdiction of the Federal District Court in which your
            address is located, or if your address is outside the United States,
            for any judicial district in which we are located; (3)a statement
            that you will accept service of process from the party that filed
            the Notification or the party’s agent; (4)your name, address, and
            telephone number; (5)a statement under penalty of perjury that you
            have a good faith belief that the material in question was removed
            or disabled as a result of a mistake or misidentification of the
            material to be removed or disabled; (6)your physical or electronic
            signature. If you send us a valid, written Counter Notification
            meeting the requirements described above, we will restore your
            removed or disabled material, unless we first receive notice from
            the party filing the Notification informing us that such party has
            filed a court action to restrain you from engaging in infringing
            activity related to the material in question. please note that if
            you materially misrepresent that the disabled or removed content was
            removed by mistake or misidentification, you may be liable for
            damages, including costs and attorney’s fees. Filing a false Counter
            Notification constitutes perjury. [Yivster,LLC]
            [Corporate@yivster.com]
          </p>
        </section>

        {/* COPYRIGHT INFRINGEMENTS */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            COPYRIGHT INFRINGEMENTS
          </h1>
          <p className="mb-4">
            We respect the intellectual property rights of others. If you
            believe that any material available on or through the platform
            infringes upon any copyright you own or control, please immediately
            notify us using the contact information provided below (a
            “Notification”). A copy of your Notification will be sent to the
            person who posted or stored the material addressed in the
            Notification. please be advised that pursuant to federal law you may
            be held liable for damages if you make material misrepresentations
            in a Notification. Thus, if you are not sure that material located
            on or linked to by the platform infringes your copyright, you should
            consider first contacting an attorney.{" "}
          </p>
        </section>

        {/* TERM AND TERMINATION */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            TERM AND TERMINATION
          </h1>
          <p className="mb-4">
            These Terms and Conditions shall remain in full force and effect
            while you use the platform. WITHOUT LIMITING ANY OTHER pROVISION OF
            THESE TERMS AND CONDITIONS, WE RESERVE THE RIGHT TO, IN OUR SOLE
            DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE
            OF THE pLATFORM (INCLUDING BLOCKING CERTAIN Ip ADDRESSES), TO ANY
            pERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION
            FOR BREACH OF ANY REpRESENTATION, WARRANTY, OR COVENANT CONTAINED IN
            THESE TERMS AND CONDITIONS OR OF ANY AppLICABLE LAW OR REGULATION.
            WE MAY TERMINATE YOUR USE OR pARTICIpATION IN THE pLATFORM OR DELETE
            [YOUR ACCOUNT AND] ANY CONTENT OR INFORMATION THAT YOU pOSTED AT ANY
            TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION. If we terminate or
            suspend your account for any reason, you are prohibited from
            registering and creating a new account under your name, a fake or
            borrowed name, or the name of any third party, even if you may be
            acting on behalf of the third party. In addition to terminating or
            suspending your account, we reserve the right to take appropriate
            legal action, including without limitation pursuing civil, criminal,
            and injunctive redress.{" "}
          </p>
        </section>

        {/* MODIFICATIONS AND INTERRUPTIONS */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            MODIFICATIONS AND INTERRUPTIONS
          </h1>
          <p className="mb-4">
            We reserve the right to change, modify, or remove the contents of
            the platform at any time or for any reason at our sole discretion
            without notice. However, we have no obligation to update any
            information on our platform. We also reserve the right to modify or
            discontinue all or part of the platform without notice at any time.
            We will not be liable to you or any third party for any
            modification, price change, suspension, or discontinuance of the
            platform. We cannot guarantee the platform will be available at all
            times. We may experience hardware, software, or other problems or
            need to perform maintenance related to the platform, resulting in
            interruptions, delays, or errors. We reserve the right to change,
            revise, update, suspend, discontinue, or otherwise modify the
            platform at any time or for any reason without notice to you. You
            agree that we have no liability whatsoever for any loss, damage, or
            inconvenience caused by your inability to access or use the platform
            during any downtime or discontinuance of the platform. Nothing in
            these Terms and Conditions will be construed to obligate us to
            maintain and support the platform or to supply any corrections,
            updates, or releases in connection therewith.{" "}
          </p>
        </section>

        {/* GOVERNING LAW */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            GOVERNING LAW
          </h1>
          <p className="mb-4">
            These Terms and Conditions and your use of the platform are governed
            by and construed in accordance with the laws of the State of
            Delaware applicable to agreements made and to be entirely performed
            within the State/Commonwealth of Delaware, without regard to its
            conflict of law principles.{" "}
          </p>
        </section>

        {/* DISPUTE RESOLUTION */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            DISPUTE RESOLUTION
          </h1>
          <p className="mb-4">
            Option 1: Any legal action of whatever nature brought by either you
            or us (collectively, the “parties” and individually, a “party”)
            shall be commenced or prosecuted in the state and federal courts
            located in Kent County, Delaware, and the parties hereby consent to,
            and waive all defenses of lack of personal jurisdiction and forum
            non conveniens with respect to venue and jurisdiction in such state
            and federal courts. Application of the United Nations Convention on
            Contracts for the International Sale of Goods and the Uniform
            Computer Information Transaction Act (UCITA) are excluded from these
            Terms and Conditions. In no event shall any claim, action, or
            proceeding brought by either party related in any way to the
            platform be commenced more than 1 year after the cause of action
            arose. Option 2: Informal Negotiations To expedite resolution and
            control the cost of any dispute, controversy, or claim related to
            these Terms and Conditions (each a “Dispute” and collectively, the
            “Disputes”) brought by either you or us (individually, a “party” and
            collectively, the “parties”), the parties agree to first attempt to
            negotiate any Dispute (except those Disputes expressly provided
            below) informally for at least 365 days before initiating
            arbitration. Such informal negotiations commence upon written notice
            from one party to the other party. Binding Arbitration If the
            parties are unable to resolve a Dispute through informal
            negotiations, the Dispute (except those Disputes expressly excluded
            below) will be finally and exclusively resolved by binding
            arbitration. YOU UNDERSTAND THAT WITHOUT THIS pROVISION, YOU WOULD
            HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
            arbitration shall be commenced and conducted under the Commercial
            Arbitration Rules of the American Arbitration Association (“AAA”)
            and, where appropriate, the AAA’s Supplementary procedures for
            Consumer Related Disputes (“AAA Consumer Rules”), both of which are
            available at the AAA website www.adr.org. Your arbitration fees and
            your share of arbitrator compensation shall be governed by the AAA
            Consumer Rules and, where appropriate, limited by the AAA Consumer
            Rules. The arbitration may be conducted in person, through the
            submission of documents, by phone, or online. The arbitrator will
            make a decision in writing, but need not provide a statement of
            reasons unless requested by either party. The arbitrator must follow
            applicable law, and any award may be challenged if the arbitrator
            fails to do so. Except where otherwise required by the applicable
            AAA rules or applicable law, the arbitration will take place in Kent
            County, Delaware. Except as otherwise provided herein, the parties
            may litigate in court to compel arbitration, stay proceedings
            pending arbitration, or to confirm, modify, vacate, or enter
            judgment on the award entered by the arbitrator. If for any reason,
            a Dispute proceeds in court rather than arbitration, the Dispute
            shall be commenced or prosecuted in the state and federal courts
            located in Kent County, Delaware, and the parties hereby consent to,
            and waive all defenses of lack of personal jurisdiction, and forum
            non conveniens with respect to venue and jurisdiction in such state
            and federal courts. Application of the United Nations Convention on
            Contracts for the International Sale of Goods and the Uniform
            Computer Information Transaction Act (UCITA) are excluded from these
            Terms and Conditions. In no event shall any Dispute brought by
            either party related in any way to the platform be commenced more
            than 1 year after the cause of action arose. If this provision is
            found to be illegal or unenforceable, then neither party will elect
            to arbitrate any Dispute falling within that portion of this
            provision found to be illegal or unenforceable and such Dispute
            shall be decided by a court of competent jurisdiction within the
            courts listed for jurisdiction above, and the parties agree to
            submit to the personal jurisdiction of that court. Option 3: Binding
            Arbitration To expedite resolution and control the cost of any
            dispute, controversy or claim related to these Terms and Conditions
            (each a “Dispute” and collectively, “Disputes”), any Dispute brought
            by either you or us (individually, a “party” and collectively, the
            “parties”) shall be finally and exclusively resolved by binding
            arbitration. YOU UNDERSTAND THAT WITHOUT THIS pROVISION, YOU WOULD
            HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
            arbitration shall be commenced and conducted under the Commercial
            Arbitration Rules of the American Arbitration Association (“AAA”)
            and, where appropriate, the AAA’s Supplementary procedures for
            Consumer Related Disputes (“AAA Consumer Rules”), both of which are
            available at the AAA website www.adr.org. Your arbitration fees and
            your share of arbitrator compensation shall be governed by the AAA
            Consumer Rules and, where appropriate, limited by the AAA Consumer
            Rules. The arbitration may be conducted in person, through the
            submission of documents, by phone, or online. The arbitrator will
            make a decision in writing, but need not provide a statement of
            reasons unless requested by either party. The arbitrator must follow
            applicable law, and any award may be challenged if the arbitrator
            fails to do so. Except where otherwise required by the applicable
            AAA rules or applicable law, the arbitration will take place in Kent
            County, Delaware. Except as otherwise provided herein, the parties
            may litigate in court to compel arbitration, stay proceedings
            pending arbitration, or to confirm, modify, vacate, or enter
            judgment on the award entered by the arbitrator. If for any reason,
            a Dispute proceeds in court rather than arbitration, the Dispute
            shall be commenced or prosecuted in the state and federal courts
            located in Kent County, Delaware, and the parties hereby consent to,
            and waive all defenses of lack of, personal jurisdiction, and forum
            non conveniens with respect to venue and jurisdiction in such state
            and federal courts. Application of the United Nations Convention on
            Contracts for the International Sale of Goods and the Uniform
            Computer Information Transaction Act (UCITA) are excluded from these
            Terms and Conditions. In no event shall any Dispute brought by
            either party related in any way to the platform or Services be
            commenced more than 1 year after the cause of action arose. If this
            provision is found to be illegal or unenforceable, then neither
            party will elect to arbitrate any Dispute falling within that
            portion of this provision found to be illegal or unenforceable and
            such Dispute shall be decided by a court of competent jurisdiction
            within the courts listed for jurisdiction above, and the parties
            agree to submit to the personal jurisdiction of that court. Option
            2/Option 3: Restrictions The parties agree that any arbitration
            shall be limited to the Dispute between the parties individually. To
            the full extent permitted by law, (a) no arbitration shall be joined
            with any other proceeding; (b) there is no right or authority for
            any Dispute to be arbitrated on a class-action basis or to utilize
            class action procedures; and (c) there is no right or authority for
            any Dispute to be brought in a purported representative capacity on
            behalf of the general public or any other persons. Option 2/Option
            3: Exceptions to [Informal Negotiations and] Arbitration The parties
            agree that the following Disputes are not subject to the above
            provisions concerning informal negotiations and] binding
            arbitration: (a) any Disputes seeking to enforce or protect, or
            concerning the validity of, any of the intellectual property rights
            of a party; (b) any Dispute related to, or arising from, allegations
            of theft, piracy, invasion of privacy, or unauthorized use; and (c)
            any claim for injunctive relief. If this provision is found to be
            illegal or unenforceable, then neither party will elect to arbitrate
            any Dispute falling within that portion of this provision found to
            be illegal or unenforceable and such Dispute shall be decided by a
            court of competent jurisdiction within the courts listed for
            jurisdiction above, and the parties agree to submit to the personal
            jurisdiction of that court.
          </p>
        </section>

        {/* CORRECTIONS */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            CORRECTIONS
          </h1>
          <p className="mb-4">
            There may be information on the platform that contains typographical
            errors, inaccuracies, or omissions that may relate to the platform,
            including descriptions, pricing, availability, and various other
            information. We reserve the right to correct any errors,
            inaccuracies, or omissions and to change or update the information
            on the platform at any time, without prior notice. DISCLAIMER THE
            pLATFORM IS pROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
            THAT YOUR USE OF THE pLATFORM AND OUR SERVICES WILL BE AT YOUR SOLE
            RISK. TO THE FULLEST EXTENT pERMITTED BY LAW, WE DISCLAIM ALL
            WARRANTIES, EXpRESS OR IMpLIED, IN CONNECTION WITH THE pLATFORM AND
            YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMpLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A pARTICULAR pURpOSE, AND
            NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REpRESENTATIONS ABOUT THE
            ACCURACY OR COMpLETENESS OF THE pLATFORM’S CONTENT OR THE CONTENT OF
            ANY WEBSITES LINKED TO THE pLATFORM AND WE WILL ASSUME NO LIABILITY
            OR RESpONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
            CONTENT AND MATERIALS, (2)pERSONAL INJURY OR pROpERTY DAMAGE, OF ANY
            NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
            pLATFORM, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
            SERVERS AND/OR ANY AND ALL pERSONAL INFORMATION AND/OR FINANCIAL
            INFORMATION STORED THEREIN, (4) ANY INTERRUpTION OR CESSATION OF
            TRANSMISSION TO OR FROM THE pLATFORM, (5)ANY BUGS, VIRUSES, TROJAN
            HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE
            pLATFORM BY ANY THIRD pARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN
            ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND
            INCURRED AS A RESULT OF THE USE OF ANY CONTENT pOSTED, TRANSMITTED,
            OR OTHERWISE MADE AVAILABLE VIA THE pLATFORM. WE DO NOT WARRANT,
            ENDORSE, GUARANTEE, OR ASSUME RESpONSIBILITY FOR ANY pRODUCT OR
            SERVICE ADVERTISED OR OFFERED BY A THIRD pARTY THROUGH THE pLATFORM,
            ANY HYpERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE AppLICATION
            FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
            pARTY TO OR IN ANY WAY BE RESpONSIBLE FOR MONITORING ANY TRANSACTION
            BETWEEN YOU AND ANY THIRD-pARTY pROVIDERS OF pRODUCTS OR SERVICES.
            AS WITH THE pURCHASE OF A pRODUCT OR SERVICE THROUGH ANY MEDIUM OR
            IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE
            CAUTION WHERE AppROpRIATE. LIMITATIONS OF LIABILITY IN NO EVENT WILL
            WE OR OUR DIRECTORS, EMpLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY
            THIRD pARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMpLARY,
            INCIDENTAL, SpECIAL, OR pUNITIVE DAMAGES, INCLUDING LOST pROFIT,
            LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE
            OF THE pLATFORM, EVEN IF WE HAVE BEEN ADVISED OF THE pOSSIBILITY OF
            SUCH DAMAGES. INDEMNIFICATION You agree to defend, indemnify, and
            hold us harmless, including our subsidiaries, affiliates, and all of
            our respective officers, agents, partners, and employees, from and
            against any loss, damage, liability, claim, or demand, including
            reasonable attorneys’ fees and expenses, made by any third party due
            to or arising out of: (1) your Contributions. (2) use of the
            platform. (3)breach of these Terms and Conditions. (4)any breach of
            your representations and warranties set forth in these Terms and
            Conditions. (5) your violation of the rights of a third party,
            including but not limited to intellectual property rights (6) any
            overt harmful act toward any other user of the platform with whom
            you connected via the platform. Notwithstanding the foregoing, we
            reserve the right, at your expense, to assume the exclusive defense
            and control of any matter for which you are required to indemnify
            us, and you agree to cooperate, at your expense, with our defense of
            such claims. We will use reasonable efforts to notify you of any
            such claim, action, or proceeding which is subject to this
            indemnification upon becoming aware of it.{" "}
          </p>
        </section>

        {/* USER DATA */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">USER DATA</h1>
          <p className="mb-4">
            We will maintain certain data that you transmit to the platform for
            the purpose of managing the platform, as well as data relating to
            your use of the platform. Although we perform regular routine
            backups of data, you are solely responsible for all data that you
            transmit or that relates to any activity you have undertaken using
            the platform. You agree that we shall have no liability to you for
            any loss or corruption of any such data, and you hereby waive any
            right of action against us arising from any such loss or corruption
            of such data. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
            SIGNATURES Visiting the platform, sending us emails, and completing
            online forms constitute electronic communications. You consent to
            receive electronic communications, and you agree that all
            agreements, notices, disclosures, and other communications we
            provide to you electronically, via email and on the platform,
            satisfy any legal requirement that such communication be in writing.
            YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS,
            ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,
            pOLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMpLETED BY US
            OR VIA THE pLATFORM. You hereby waive any rights or requirements
            under any statutes, regulations, rules, ordinances, or other laws in
            any jurisdiction which require an original signature or delivery or
            retention of non-electronic records, or to payments or the granting
            of credits by any means other than electronic means. Policy for
            inappropriate Content Yivster has the right but not the obligation,
            to remove content that we determine, in our sole discretion, to be
            "offensive content" as defined below. We also have the right, but
            not the obligation, to limit or revoke the use of privileges of
            anyone who post such offensive content or engages in otherwise
            unacceptable behavior on or through the services. Yivster maintains
            a zero-tolerance policy regarding objectionable content.
            Objectionable content may not be uploaded or displayed to the extent
            such content includes, is in conjunction with, or alongside any,
            objectionable content. Objectionable content includes but is not
            limited to (1)Sexually explicit content. (2) Hate speech, racial,
            slander, defamation, harassment, misinformation, violent or unlawful
            language or action. (3) Content that infringes on another third
            party including: copyright, trademark, privacy, publicity or is
            deceptive or fraudulent. (4)Content that promotes the use of illegal
            or regulated substances. (5) Gambling without limitation, any online
            casino, sports books, bingo, and poker. Any user has the right to
            flag a channel or content that they deem objectionable for review.
            Content will be moderated by the Yivster team in order to ensure
            timely review and the safety of users on the platform. User accounts
            who have been reviewed and confirmed by the Yivster team of
            violating the objectionable content rule can face a possible
            restriction or ban on their account.{" "}
          </p>
        </section>

        {/* CALIFORNIA USERS AND RESIDENTS  */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            CALIFORNIA USERS AND RESIDENTS
          </h1>
          <p className="mb-4">
            If any complaint with us is not satisfactorily resolved, you can
            contact the Complaint Assistance Unit of the division of Consumer
            Services of the California Department of Consumer Affairs in writing
            at 1625 North Market Blvd., Suite N 112, Sacramento, California
            95834 or by telephone at (800) 952-5210 or (916) 445-1254.
          </p>
        </section>

        {/* MISCELLANEOUS */}
        <section>
          <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
            MISCELLANEOUS
          </h1>
          <p className="mb-4">
            These Terms and Conditions and any policies or operating rules
            posted by us on the platform constitute the entire agreement and
            understanding between you and us. Our failure to exercise or enforce
            any right or provision of these Terms and Conditions shall not
            operate as a waiver of such right or provision. These Terms and
            Conditions operate to the fullest extent permissible by law. We may
            assign any or all of our rights and obligations to others at any
            time. We shall not be responsible or liable for any loss, damage,
            delay, or failure to act caused by any cause beyond our reasonable
            control. If any provision or part of a provision of these Terms and
            Conditions is determined to be unlawful, void, or unenforceable,
            that provision or part of the provision is deemed severable from
            these Terms and Conditions and does not affect the validity and
            enforceability of any remaining provisions. There is no joint
            venture, partnership, employment or agency relationship created
            between you and us as a result of these Terms and Conditions or use
            of the platform. You agree that these Terms and Conditions will not
            be construed against us by virtue of having drafted them. You hereby
            waive any and all defenses you may have based on the electronic form
            of these Terms and Conditions and the lack of signing by the parties
            hereto to execute these Terms and Conditions.{" "}
          </p>
        </section>

        {/* Important Notice Boxes */}
        <div className="">
          <p className="text-[#4cc9f0] font-bold mb-2">Important Notice:</p>
          <p className="text-black-300">
            All content posted on our platform must comply with our Community
            Guidelines and applicable laws.
          </p>
        </div>

        {/* Contact Section */}
        <section>
          <h2 className="text-[#4cc9f0] text-3xl font-bold mb-6">CONTACT US</h2>
          <div className="text-black-300">
            In order to resolve a complaint regarding the platform or to receive
            further information regarding use of the platform, please contact us
            at:
            <p className="mt-4">
              <span className="text-[#4cc9f0]">Email:</span>{" "}
              corporate@yivster.com
            </p>
          </div>
        </section>
      </div>

      {/* Footer */}
      <div className="mt-12 pt-8 border-t border-gray-800">
        <p className="text-black-400 text-sm">
          © {new Date().getFullYear()} Yivster LLC. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default TermsPage;
