import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentUser: null,
  playlists: [],
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearUser: (state) => {
      state.currentUser = null;
    },
    setPlaylists: (state, action) => {
      state.playlists = action.payload;
    },
    addPlaylist: (state, action) => {
      state.playlists.push(action.payload);
    },
    updatePlaylist: (state, action) => {
      const index = state.playlists.findIndex(
        (p) => p._id === action.payload._id
      );
      if (index !== -1) {
        state.playlists[index] = action.payload;
      }
    },
  },
});

export const {
  setUser,
  setLoading,
  setError,
  clearUser,
  setPlaylists,
  addPlaylist,
  updatePlaylist,
} = userSlice.actions;

export default userSlice.reducer;
