import React, { useState, useEffect } from "react";
import { X, Lock } from "lucide-react";
import { useToast } from "../shared/Toast";
import { useDispatch, useSelector } from "react-redux";
import { setPlaylists, addPlaylist } from "../../redux/slices/userSlice";
import api from "../../config/axios";

const SaveToPlaylistModal = ({ isOpen, onClose, video }) => {
  const dispatch = useDispatch();
  const playlists = useSelector((state) => state.user.playlists);
  const [newPlaylistTitle, setNewPlaylistTitle] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [selectedPlaylists, setSelectedPlaylists] = useState(new Set());
  const { showToast, ToastComponent } = useToast();

  useEffect(() => {
    if (isOpen) {
      fetchPlaylists();
    }
  }, [isOpen]);

  /*   const fetchPlaylists = async () => {
    try {
      const response = await api.get("/users/playlists");
      dispatch(setPlaylists(response.data));
    } catch (error) {
      showToast("Failed to fetch playlists", "error");
    }
  }; */

  const fetchPlaylists = async () => {
    try {
      const response = await api.get("/users/playlists");
      dispatch(setPlaylists(response.data));

      // Check which playlists already contain this video
      const initialSelected = new Set();
      response.data.forEach((playlist) => {
        if (playlist.videos.some((v) => v._id === video._id)) {
          initialSelected.add(playlist._id);
        }
      });
      setSelectedPlaylists(initialSelected);
    } catch (error) {
      showToast("Failed to fetch playlists", "error");
    }
  };

  const createPlaylist = async () => {
    try {
      const response = await api.post("/users/playlists", {
        title: newPlaylistTitle,
      });
      dispatch(addPlaylist(response.data.playlist));

      // Add video to the newly created playlist
      await api.post("/users/playlists/add", {
        playlistId: response.data.playlist._id,
        videoId: video._id,
      });

      // Update selected playlists
      const newSelected = new Set(selectedPlaylists);
      newSelected.add(response.data.playlist._id);
      setSelectedPlaylists(newSelected);

      showToast(
        `Created playlist "${newPlaylistTitle}" and added video`,
        "success"
      );
      setNewPlaylistTitle("");
      setIsCreating(false);
    } catch (error) {
      showToast("Failed to create playlist", "error");
    }
  };

  /*   const togglePlaylist = async (playlistId) => {
    try {
      const isSelected = selectedPlaylists.has(playlistId);
      const playlist = playlists.find((p) => p._id === playlistId);

      if (isSelected) {
        // Video is already in playlist, show message
        showToast(`Video is already in "${playlist.title}"`, "info");
        return;
      }

      await api.post("/users/playlists/add", {
        playlistId,
        videoId: video._id,
      });

      // Update local state
      const newSelected = new Set(selectedPlaylists);
      newSelected.add(playlistId);
      setSelectedPlaylists(newSelected);

      showToast(`Added to "${playlist.title}"`, "success");
    } catch (error) {
      showToast(
        error.response?.data?.message || "Failed to update playlist",
        "error"
      );
    }
  }; */
  /*   const togglePlaylist = async (playlistId) => {
    try {
      const isSelected = selectedPlaylists.has(playlistId);
      const playlist = playlists.find((p) => p._id === playlistId);

      if (isSelected) {
        // Remove video from playlist using new endpoint
        await api.post("/users/playlists/remove", {
          playlistId,
          videoId: video._id,
        });

        const newSelected = new Set(selectedPlaylists);
        newSelected.delete(playlistId);
        setSelectedPlaylists(newSelected);

        showToast(`Removed from "${playlist.title}"`, "success");
      } else {
        // Add to playlist
        await api.post("/users/playlists/add", {
          playlistId,
          videoId: video._id,
        });

        const newSelected = new Set(selectedPlaylists);
        newSelected.add(playlistId);
        setSelectedPlaylists(newSelected);

        showToast(`Added to "${playlist.title}"`, "success");
      }
    } catch (error) {
      showToast(
        error.response?.data?.message || "Failed to update playlist",
        "error"
      );
    }
  }; */

  const togglePlaylist = async (playlistId) => {
    try {
      const isSelected = selectedPlaylists.has(playlistId);
      const playlist = playlists.find((p) => p._id === playlistId);

      if (isSelected) {
        // Remove video from playlist using new endpoint
        await api.post("/users/playlists/remove", {
          playlistId,
          videoId: video._id,
        });

        // Update local checkbox state
        const newSelected = new Set(selectedPlaylists);
        newSelected.delete(playlistId);
        setSelectedPlaylists(newSelected);

        // Update Redux state
        const updatedPlaylists = playlists.map((p) => {
          if (p._id === playlistId) {
            return {
              ...p,
              videos: p.videos.filter((v) => v._id !== video._id),
            };
          }
          return p;
        });
        dispatch(setPlaylists(updatedPlaylists));

        showToast(`Removed from "${playlist.title}"`, "success");
      } else {
        // Add to playlist
        await api.post("/users/playlists/add", {
          playlistId,
          videoId: video._id,
        });

        // Update local checkbox state
        const newSelected = new Set(selectedPlaylists);
        newSelected.add(playlistId);
        setSelectedPlaylists(newSelected);

        // Update Redux state
        const updatedPlaylists = playlists.map((p) => {
          if (p._id === playlistId) {
            return {
              ...p,
              videos: [...p.videos, video],
            };
          }
          return p;
        });
        dispatch(setPlaylists(updatedPlaylists));

        showToast(`Added to "${playlist.title}"`, "success");
      }

      // Fetch fresh data after update
      fetchPlaylists();
    } catch (error) {
      showToast(
        error.response?.data?.message || "Failed to update playlist",
        "error"
      );
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/60 flex items-center justify-center z-[1000]">
      <div className="bg-white w-full max-w-[400px] mx-4 rounded-xl">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-medium">Save to...</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={20} />
          </button>
        </div>

        <div className="p-4 max-h-[400px] overflow-y-auto">
          {playlists.map((playlist) => (
            <div
              key={playlist._id}
              className="flex items-center gap-3 p-2 hover:bg-gray-100 rounded-lg"
            >
              <input
                type="checkbox"
                checked={selectedPlaylists.has(playlist._id)}
                onChange={() => togglePlaylist(playlist._id)}
                className="appearance-none w-5 h-5 border-2 border-gray-300 rounded-sm checked:bg-[#B94EEF] checked:border-[#B94EEF] relative
    before:content-[''] before:w-3 before:h-2 before:border-white before:border-r-2 before:border-b-2 before:absolute 
    before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-[60%] before:rotate-45 before:opacity-0
    checked:before:opacity-100 cursor-pointer transition-all"
              />
              <span className="flex-1">{playlist.title}</span>
              <Lock className="w-4 h-4 text-gray-400" />{" "}
              {/* Lock icon for private */}
            </div>
          ))}

          {isCreating ? (
            <div className="mt-4">
              <input
                type="text"
                value={newPlaylistTitle}
                onChange={(e) => setNewPlaylistTitle(e.target.value)}
                placeholder="Enter playlist name"
                className="w-full p-2 border rounded-lg mb-2"
                autoFocus
              />
              <div className="flex justify-end gap-2 mt-2">
                <button
                  onClick={() => setIsCreating(false)}
                  className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={createPlaylist}
                  disabled={!newPlaylistTitle.trim()}
                  className="px-4 py-2 text-white bg-[#B94EEF] rounded hover:bg-[#B94EEF]/90 disabled:opacity-50"
                >
                  CREATE
                </button>
              </div>
            </div>
          ) : (
            <button
              onClick={() => setIsCreating(true)}
              className="mt-4 w-full py-3 text-[#B94EEF] hover:bg-gray-100 rounded-lg"
            >
              + Create new playlist
            </button>
          )}
        </div>
      </div>
      {ToastComponent}
    </div>
  );
};

export default SaveToPlaylistModal;
