import LoadingSkeleton from "./LoadingSkeleton";

function VideoCardSkeleton() {
  return (
    <div className="flex flex-col">
      {/* Thumbnail */}
      <LoadingSkeleton className="w-full aspect-video rounded-lg mb-3" />

      {/* Channel and Info */}
      <div className="flex gap-3">
        <LoadingSkeleton className="w-9 h-9 rounded-full flex-shrink-0" />
        <div className="flex-1">
          <LoadingSkeleton className="h-5 w-full mb-2" />
          <LoadingSkeleton className="h-4 w-3/4" />
          <LoadingSkeleton className="h-4 w-1/2 mt-2" />
        </div>
      </div>
    </div>
  );
}

export default VideoCardSkeleton;
