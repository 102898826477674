import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SignInButton, useAuth } from "@clerk/clerk-react";
import {
  AiOutlineLike,
  AiFillLike,
  AiOutlineDislike,
  AiFillDislike,
} from "react-icons/ai";
import { RiShareForwardLine } from "react-icons/ri";
import { BsThreeDots } from "react-icons/bs";
import { BiMoney, BiDownload, BiCut, BiBookmark, BiFlag } from "react-icons/bi";
import ShareModal from "./ShareModal"; // Adjust the import path as needed
import ReportModal from "./ReportModal";
import SaveToPlaylistModal from "./SaveToPlaylistModal";

import { updateVideoInteractions } from "../../redux/slices/videoSlice";
import { selectCanReceiveTips } from "../../redux/slices/stripeSlice";

import api from "../../config/axios";

function VideoInteractions({ video, channelInfo, onTipClick }) {
  // Add these props
  const dispatch = useDispatch();
  const { isSignedIn, getToken } = useAuth();
  const canReceiveTips = useSelector(selectCanReceiveTips);
  //console.log("canReceiveTips", canReceiveTips);

  const [showSignInPrompt, setShowSignInPrompt] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  // Close menu when clicking outside
  const handleClickOutside = (e) => {
    if (showMenu && !e.target.closest(".menu-container")) {
      setShowMenu(false);
    }
  };

  useState(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showMenu]);

  const handleLike = async () => {
    if (!isSignedIn) {
      setShowSignInPrompt(true);
      return;
    }

    try {
      const response = await api.post(`/videos/${video._id}/like`);

      dispatch(
        updateVideoInteractions({
          likesCount: response.data.likesCount,
          dislikesCount: response.data.dislikesCount,
          isLiked: response.data.isLiked,
          isDisliked: response.data.isDisliked,
        })
      );
    } catch (error) {
      console.error("Error liking video:", error);
    }
  };

  const handleDislike = async () => {
    if (!isSignedIn) {
      setShowSignInPrompt(true);
      return;
    }

    try {
      const response = await api.post(`/videos/${video._id}/dislike`);

      dispatch(
        updateVideoInteractions({
          likesCount: response.data.likesCount,
          dislikesCount: response.data.dislikesCount,
          isLiked: response.data.isLiked,
          isDisliked: response.data.isDisliked,
        })
      );
    } catch (error) {
      console.error("Error disliking video:", error);
    }
  };

  const handleTipClick = () => {
    if (!isSignedIn) {
      setShowSignInPrompt(true);
      return;
    }
    onTipClick();
  };

  const formatCount = (count) => {
    if (count >= 1000000) {
      return `${(count / 1000000).toFixed(1)}M`;
    }
    if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}K`;
    }
    return count;
  };

  return (
    <>
      <div className="flex items-center gap-4 mt-4">
        <div className="flex items-center gap-2 bg-gray-100 rounded-full">
          <button
            onClick={handleLike}
            className={`flex items-center gap-1 px-4 py-2 rounded-l-full hover:bg-gray-200 ${
              video.isLiked ? "text-blue-600" : ""
            }`}
          >
            {video.isLiked ? (
              <AiFillLike className="text-xl" />
            ) : (
              <AiOutlineLike className="text-xl" />
            )}
            <span>{formatCount(video.likesCount || 0)}</span>
          </button>
          <div className="w-px h-6 bg-gray-300" />
          <button
            onClick={handleDislike}
            className={`flex items-center gap-1 px-4 py-2 rounded-r-full hover:bg-gray-200 ${
              video.isDisliked ? "text-blue-600" : ""
            }`}
          >
            {video.isDisliked ? (
              <AiFillDislike className="text-xl" />
            ) : (
              <AiOutlineDislike className="text-xl" />
            )}
            <span>{formatCount(video.dislikesCount || 0)}</span>
          </button>
        </div>
        <button
          onClick={() => setIsShareModalOpen(true)}
          className="flex items-center gap-2 px-4 py-2 rounded-full hover:bg-gray-100"
        >
          <RiShareForwardLine className="text-xl" />
          <span>Share</span>
        </button>
        {canReceiveTips ? (
          <button
            onClick={handleTipClick}
            className="flex items-center gap-2 px-4 py-2 rounded-full text-white"
            style={{ backgroundColor: "#B94EEF" }}
          >
            <BiMoney className="text-xl" />
            <span>Send Tip</span>
          </button>
        ) : null}
        {/*      <button className="flex items-center px-4 py-2 rounded-full hover:bg-gray-100 ml-auto">
          <BsThreeDots className="text-xl" />
        </button> */}
        <div className="relative ml-auto menu-container">
          <button
            onClick={() => setShowMenu(!showMenu)}
            className="flex items-center px-4 py-2 rounded-full hover:bg-gray-100"
          >
            <BsThreeDots className="text-xl" />
          </button>

          {showMenu && (
            <div className="absolute right-0 bottom-full mb-2 w-48 bg-white rounded-md shadow-lg py-1 z-50">
              <button
                className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                onClick={() => {
                  if (!isSignedIn) {
                    setShowSignInPrompt(true);
                    setShowMenu(false);
                    return;
                  }
                  setShowSaveModal(true);
                  setShowMenu(false);
                }}
              >
                <BiBookmark className="text-lg" />
                Save
              </button>
              <button
                className="flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full"
                onClick={() => {
                  if (!isSignedIn) {
                    setShowSignInPrompt(true);
                    setShowMenu(false);
                    return;
                  }
                  setIsReportModalOpen(true);
                  setShowMenu(false);
                }}
              >
                <BiFlag className="text-lg" />
                Report
              </button>
            </div>
          )}
        </div>
      </div>

      <ShareModal
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        currentVideo={video}
        channelInfo={channelInfo}
      />

      <ReportModal
        videoId={video._id}
        isOpen={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
      />

      {showSaveModal && (
        <SaveToPlaylistModal
          isOpen={showSaveModal}
          onClose={() => setShowSaveModal(false)}
          video={video}
        />
      )}

      {/* Sign In Modal */}
      {showSignInPrompt && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg max-w-sm w-full mx-4">
            <h3 className="text-lg font-bold mb-2 dark:text-white">
              Sign in to interact
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Sign in to like, dislike, or send a tip!
            </p>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowSignInPrompt(false)}
                className="px-4 py-2 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
              >
                Cancel
              </button>
              <SignInButton mode="modal">
                <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                  Sign In
                </button>
              </SignInButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default VideoInteractions;
