import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "@clerk/clerk-react";
import { TrashIcon } from "lucide-react";
import { Link } from "react-router-dom";
import {
  formatDuration,
  formatViewCount,
  timeAgo,
} from "../../../utils/formatters";
import VideoCard from "../../../components/shared/VideoCard";
import VideoCardSkeleton from "../../../components/shared/VideoCardSkeleton";
import SignInPrompt from "../../../components/shared/SignInPrompt";
import { useToast } from "../../../components/shared/Toast";
import {
  setHistory,
  setLoading,
  setError,
  setPagination,
  clearHistory,
  removeFromHistory,
} from "../../../redux/slices/watchHistorySlice";
import api from "../../../config/axios";

function WatchHistoryPage({ isSidebarOpen }) {
  const dispatch = useDispatch();
  const { isSignedIn } = useAuth();
  const { showToast, ToastComponent } = useToast();
  const [showSignInPrompt, setShowSignInPrompt] = useState(false);
  const { history, loading, error, pagination } = useSelector(
    (state) => state.watchHistory
  );
  const [page, setPage] = useState(1);

  useEffect(() => {
    const getHistory = async () => {
      if (isSignedIn) {
        try {
          dispatch(setLoading(true));
          const response = await api.get(`/history?page=${page}&limit=20`);

          // Ensure we have lastPosition from the history items
          const historyWithProgress = response.data.data.map((item) => ({
            ...item,
            lastPosition: item.lastPosition || 0, // Make sure lastPosition exists
          }));

          dispatch(setHistory(historyWithProgress));
          dispatch(setPagination(response.data.pagination));
        } catch (error) {
          dispatch(setError(error.message));
        } finally {
          dispatch(setLoading(false));
        }
      }
    };

    getHistory();
  }, [dispatch, isSignedIn, page]);

  const handleClearHistory = async () => {
    if (
      window.confirm(
        "Are you sure you want to clear your entire watch history?"
      )
    ) {
      try {
        dispatch(setLoading(true));
        await api.delete("/history");
        dispatch(clearHistory());
        showToast("Watch history cleared successfully", "success");
      } catch (error) {
        dispatch(setError(error.message));
        showToast("Failed to clear watch history", "error");
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const handleRemoveVideo = async (videoId) => {
    try {
      dispatch(setLoading(true));
      await api.delete(`/history/${videoId}`);
      dispatch(removeFromHistory(videoId));
      showToast("Video removed from history", "success");
    } catch (error) {
      dispatch(setError(error.message));
      showToast("Failed to remove video", "error");
    } finally {
      dispatch(setLoading(false));
    }
  };

  const groupedHistory = history.reduce((groups, item) => {
    const date = new Date(item.watchedAt).toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(item);
    return groups;
  }, {});

  if (!isSignedIn) {
    return (
      <SignInPrompt
        isOpen={true}
        onClose={() => setShowSignInPrompt(false)}
        action="view your watch history"
      />
    );
  }

  if (loading) {
    return (
      <div className="px-8 py-6">
        <h1 className="text-2xl font-bold mb-6">Watch History</h1>
        <div
          className={`grid grid-cols-1 ${
            isSidebarOpen
              ? "md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
              : "md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5"
          } gap-4`}
        >
          {[...Array(12)].map((_, i) => (
            <VideoCardSkeleton key={i} />
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="px-8 py-6">
        <h1 className="text-2xl font-bold mb-6">Watch History</h1>
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  if (!history.length) {
    return (
      <div className="px-8 py-6">
        <h1 className="text-2xl font-bold mb-6">Watch History</h1>
        <div className="text-center py-20">
          <p className="text-gray-600">
            {" "}
            Can't Beleive You haven't Watched Any Videos Yet{" "}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="px-8 py-6 max-w-[1200px] mx-auto">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold">Watch History</h1>
        <button
          onClick={handleClearHistory}
          className="flex items-center gap-2 px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg"
        >
          <TrashIcon size={20} />
          Clear all watch history
        </button>
      </div>

      {/* Videos List Grouped by Date */}
      <div className="flex flex-col space-y-8">
        {Object.entries(groupedHistory).map(([date, items]) => (
          <div key={date}>
            <h2 className="text-lg font-medium text-gray-900 mb-4">{date}</h2>

            <div className="space-y-4">
              {items.map((item) => (
                <div
                  key={item._id}
                  className="flex gap-4 group hover:bg-gray-50 p-2 rounded-lg"
                >
                  {/* Thumbnail */}
                  <Link
                    to={`/video/${item.videoId._id}`}
                    className="relative flex-shrink-0"
                  >
                    <img
                      src={item.videoId.thumbnailUrl}
                      alt={item.videoId.title}
                      className="w-48 aspect-video object-cover rounded-lg"
                    />
                    <span className="absolute bottom-1 right-1 bg-black bg-opacity-80 text-white text-xs px-1 rounded">
                      {formatDuration(item.videoId.duration)}
                    </span>
                    {/* Progress Bar */}
                    {item.lastPosition > 0 && item.videoId.duration > 0 && (
                      <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-800 bg-opacity-60">
                        <div
                          className="h-full bg-[#9147ff]"
                          style={{
                            width: `${Math.min(
                              (item.lastPosition / item.videoId.duration) * 100,
                              100
                            )}%`,
                          }}
                        />
                      </div>
                    )}
                  </Link>

                  {/* Video Info */}
                  <div className="flex-1 min-w-0">
                    <Link to={`/video/${item.videoId._id}`}>
                      <h3 className="text-base font-medium mb-1 line-clamp-2">
                        {item.videoId.title}
                      </h3>
                    </Link>

                    <Link
                      to={`/channel/${item.videoId.channelId?.handle}`}
                      className="text-sm text-gray-600 hover:text-gray-900"
                    >
                      {item.videoId.channelId?.name}
                    </Link>

                    <div className="text-sm text-gray-600 mt-1">
                      <span>{formatViewCount(item.videoId.views)} views</span>
                      <span className="mx-1">•</span>
                      <span>{timeAgo(item.videoId.createdAt)}</span>
                    </div>
                  </div>

                  {/* Remove Button */}
                  <button
                    onClick={() => handleRemoveVideo(item.videoId._id)}
                    className="p-2 text-gray-500 hover:text-red-600 opacity-0 group-hover:opacity-100 transition-opacity"
                  >
                    <TrashIcon size={20} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Pagination */}
      {pagination.pages > 1 && (
        <div className="flex justify-center gap-2 mt-8">
          {[...Array(pagination.pages)].map((_, i) => (
            <button
              key={i}
              onClick={() => setPage(i + 1)}
              className={`px-4 py-2 rounded ${
                page === i + 1
                  ? "bg-[#9147ff] text-white"
                  : "bg-gray-100 hover:bg-gray-200"
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      )}
      {ToastComponent}
    </div>
  );
}

export default WatchHistoryPage;
