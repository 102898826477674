import React from "react";
import { Lock } from "lucide-react";
import { Link } from "react-router-dom";

const PlaylistCard = ({ playlist }) => {
  // Get first video ID - add this at the top
  const firstVideoId = playlist.videos[0]?._id;

  return (
    <Link
      to={firstVideoId ? `/video/${firstVideoId}?list=${playlist._id}` : "#"}
      className={`block ${!firstVideoId ? "cursor-not-allowed" : ""}`}
    >
      <div className="relative group">
        <div className="w-full relative">
          <div className="aspect-video relative">
            {playlist.videos.length > 0 ? (
              <img
                src={playlist.videos[0].thumbnailUrl}
                alt=""
                className="w-full h-full object-cover rounded-lg"
              />
            ) : (
              <div className="w-full h-full bg-gray-200 rounded-lg flex items-center justify-center">
                <span className="text-gray-400">No videos</span>
              </div>
            )}
            <div className="absolute bottom-0 right-0 bg-black/80 text-white px-2 py-1 text-sm rounded-tl">
              {playlist.videos.length}{" "}
              {playlist.videos.length === 1 ? "video" : "videos"}
            </div>
          </div>
        </div>
        <div className="mt-2">
          <h3 className="text-sm font-medium line-clamp-2">{playlist.title}</h3>
          <div className="text-xs text-gray-600 mt-1 flex items-center gap-1">
            <Lock className="w-3 h-3" />
            Private
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PlaylistCard;
