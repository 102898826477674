import LoadingSkeleton from "../shared/LoadingSkeleton";

function VideoPageSkeleton() {
  return (
    <div className="flex flex-col lg:flex-row gap-4 p-4">
      {/* Video Player Skeleton */}
      <div className="flex-grow lg:w-3/4">
        <LoadingSkeleton className="w-full aspect-video rounded-lg" />

        {/* Video Info Skeleton */}
        <div className="mt-4">
          <LoadingSkeleton className="h-7 w-3/4 mb-4" />

          <div className="flex items-center justify-between mt-2">
            <div className="flex items-center gap-4">
              <LoadingSkeleton className="w-10 h-10 rounded-full" />
              <div>
                <LoadingSkeleton className="h-5 w-32 mb-2" />
                <LoadingSkeleton className="h-4 w-24" />
              </div>
            </div>
            <LoadingSkeleton className="w-24 h-10 rounded-full" />
          </div>

          {/* Description Skeleton */}
          <div className="mt-4">
            <LoadingSkeleton className="h-20 w-full rounded-xl" />
          </div>
        </div>
      </div>

      {/* Suggested Videos Skeleton */}
      <div className="lg:w-1/4">
        <LoadingSkeleton className="h-6 w-40 mb-4" />
        {[...Array(5)].map((_, i) => (
          <div key={i} className="flex gap-2 mb-4">
            <LoadingSkeleton className="w-40 h-20 rounded-lg" />
            <div className="flex-1">
              <LoadingSkeleton className="h-4 w-full mb-2" />
              <LoadingSkeleton className="h-4 w-2/3" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default VideoPageSkeleton;
