import { Routes, Route } from "react-router-dom";
import TermsPage from "../../pages/Legal/Terms";
import PrivacyPolicy from "../../pages/Legal/PrivacyPolicy";
import CommunityGuidelines from "../../pages/Legal/CommunityGuidelines";

const LegalLayout = () => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* You might want to create a minimal version of your navbar */}
      <div className="flex-1 bg-gray-50">
        <Routes>
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/guidelines" element={<CommunityGuidelines />} />
        </Routes>
      </div>
    </div>
  );
};

export default LegalLayout;
