import React, { useState } from "react";
import { IoClose } from "react-icons/io5";
import { useToast } from "../../components/shared/Toast";
import api from "../../config/axios";

const ReportModal = ({ videoId, isOpen, onClose }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showToast, ToastComponent } = useToast();

  const reportReasons = [
    "Sexual content",
    "Violent or repulsive content",
    "Hateful or abusive content",
    "Harassment or bullying",
    "Harmful or dangerous acts",
    "Misinformation",
    "Child abuse",
    "Promotes terrorism",
    "Spam or misleading",
    "Copyright issue",
    "Captions issue",
  ];

  const handleSubmit = async () => {
    if (!selectedReason) {
      showToast("Please select a reason for reporting", "error");
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await api.post(`/videos/${videoId}/report`, {
        reason: selectedReason,
        description: description.trim(),
      });

      showToast("Report submitted successfully", "success");

      setTimeout(() => {
        setSelectedReason("");
        setDescription("");
        onClose();
      }, 500);
    } catch (error) {
      showToast(
        error.response?.data?.message || "Failed to submit report",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/60 flex items-center justify-center z-[1000]">
      <div className="bg-white w-full max-w-[800px] mx-4 rounded-xl">
        {/* Header */}
        <div className="flex justify-between items-center px-6 py-4 border-b border-gray-200">
          <h2 className="text-xl">Report video</h2>
          <button
            onClick={onClose}
            className="text-gray-600 hover:bg-gray-100 p-2 rounded-full"
          >
            <IoClose size={20} />
          </button>
        </div>

        {/* Content */}
        <div className="flex">
          {/* Left side - Radio options */}
          <div className="w-1/2 px-6 py-4 border-r border-gray-200">
            {reportReasons.map((reason) => (
              <label
                key={reason}
                className="flex items-center py-2 px-2 rounded-lg hover:bg-gray-50 cursor-pointer transition-colors"
              >
                <div className="relative flex items-center">
                  <input
                    type="radio"
                    name="reportReason"
                    value={reason}
                    checked={selectedReason === reason}
                    onChange={(e) => setSelectedReason(e.target.value)}
                    className="appearance-none w-4 h-4 rounded-full border border-gray-300 checked:border-[#B94EEF] checked:border-[5px] transition-all duration-200 cursor-pointer focus:outline-none focus:ring-2 focus:ring-[#B94EEF] focus:ring-offset-2"
                  />
                </div>
                <span className="ml-3 text-sm text-gray-900">{reason}</span>
              </label>
            ))}
          </div>

          {/* Right side - Textarea */}
          <div className="w-1/2 px-6 py-4">
            <label className="block text-sm font-medium text-gray-900 mb-2">
              Additional details (optional)
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              maxLength={300}
              placeholder="Provide any additional context..."
              className="w-full h-[250px] px-3 py-2 text-sm border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-[#B94EEF] focus:border-transparent placeholder-gray-500"
            />
          </div>
        </div>

        {/* Footer */}
        <div className="px-6 py-4 border-t border-gray-200 flex justify-end space-x-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100 rounded-full"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={!selectedReason || isSubmitting}
            className="px-6 py-2 text-sm font-medium text-white bg-[#B94EEF] rounded-full hover:bg-[#B94EEF]/90 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
      {ToastComponent}
    </div>
  );
};

export default ReportModal;
