import axios from "axios";

const api = axios.create({
  //development mode
  //  baseURL: "http://localhost:5000/api",
  // baseURL: process.env.REACT_APP_API_URL,
  //baseURL: "/api", // This is all you need - the proxy will handle the rest
  baseURL: "/api", // Keep this relative path for the proxy
  withCredentials: true,
});

// Add Clerk token to requests
api.interceptors.request.use(async (config) => {
  const token = await window.Clerk?.session?.getToken();
  //console.log("Token obtained:", token ? "Token exists" : "No token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    //console.log("Headers after setting:", config.headers);
  }
  return config;
});

export default api;
