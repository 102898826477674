// In slices/stripeSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  stripeStatus: {
    tipsEnabled: false,
    monetizationEnabled: false,
    // Future features:
    // membershipsEnabled: false,
  },
  stripeAccountId: null,
};

const stripeSlice = createSlice({
  name: "stripe",
  initialState,
  reducers: {
    setStripeLoading: (state, action) => {
      state.loading = action.payload;
    },
    setStripeError: (state, action) => {
      state.error = action.payload;
    },
    setStripeStatus: (state, action) => {
      state.stripeStatus = {
        ...state.stripeStatus,
        ...action.payload,
      };
    },
    setStripeAccountId: (state, action) => {
      state.stripeAccountId = action.payload;
    },
    // We can add more reducers as needed
  },
});

// Selectors
export const selectStripeStatus = (state) => state.stripe.stripeStatus;
export const selectCanReceiveTips = (state) =>
  state.stripe.stripeStatus.tipsEnabled &&
  Boolean(state.stripe.stripeAccountId);

export const {
  setStripeLoading,
  setStripeError,
  setStripeStatus,
  setStripeAccountId,
} = stripeSlice.actions;

export default stripeSlice.reducer;
