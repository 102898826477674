import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useUser } from "@clerk/clerk-react";
import { setUser, setLoading } from "../../redux/slices/authSlice";

function AuthProvider({ children }) {
  const { isLoaded, isSignedIn, user } = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoaded) {
      if (isSignedIn && user) {
        dispatch(
          setUser({
            id: user.id,
            email: user.primaryEmailAddress?.emailAddress,
            firstName: user.firstName,
            lastName: user.lastName,
            imageUrl: user.imageUrl,
          })
        );
      } else {
        dispatch(setUser(null));
      }
      dispatch(setLoading(false));
    }
  }, [isLoaded, isSignedIn, user, dispatch]);

  return children;
}

export default AuthProvider;
