import React from "react";
import { SignInButton, useAuth } from "@clerk/clerk-react";
import UserHeader from "./UserHeader";
import LikedVideosPreview from "./LikedVideos/LikedVideosPreview";
import WatchHistoryPreview from "./WatchHistory/WatchHistoryPreview";
import PlaylistsPreview from "./Playlists/PlaylistsPreview";

function YouPage() {
  const { isSignedIn } = useAuth();

  if (!isSignedIn) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[70vh] px-4">
        <div className="text-center max-w-md">
          <h2 className="text-2xl font-bold mb-4">
            Ahh Not So Fast Sign in to view your profile
          </h2>
          <p className="text-gray-600 mb-8">
            Sign in to keep track of your favorite content and manage your
            Yivster experience.
          </p>
          <SignInButton mode="modal">
            <button className="px-6 py-3 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors">
              Sign In
            </button>
          </SignInButton>
        </div>
      </div>
    );
  }

  return (
    <div>
      <UserHeader />
      <div className="py-6">
        <div className="space-y-8">
          <WatchHistoryPreview />
          <LikedVideosPreview />
          <PlaylistsPreview />
          {/* Other sections */}
        </div>
      </div>
    </div>
  );
}

export default YouPage;
