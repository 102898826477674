// components/video/AddComment.jsx
import { useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import SignInPrompt from "../shared/SignInPrompt";

function AddComment({ onSubmit, userImage }) {
  const [commentText, setCommentText] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [showSignInPrompt, setShowSignInPrompt] = useState(false);
  const { isSignedIn } = useAuth();

  const displayImage =
    isSignedIn && userImage
      ? userImage
      : "https://img.clerk.com/eyJ0eXBlIjoiZGVmYXVsdCIsImlpZCI6Imluc18ycEpJRm1YSTJsTUlJcU9BZGVoTzFyNndmWUYiLCJyaWQiOiJ1c2VyXzJwamd0Y0oyR1lqZDVldUt3d0tNb01XMHRkZyJ9";

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isSignedIn) {
      setShowSignInPrompt(true);
      return;
    }
    if (commentText.trim()) {
      onSubmit(commentText);
      setCommentText("");
      setIsInputFocused(false);
    }
  };

  return (
    <div className="flex gap-4">
      <img
        src={displayImage}
        alt="user"
        className="w-10 h-10 rounded-full"
        onError={(e) => {
          e.target.src =
            "https://img.clerk.com/eyJ0eXBlIjoiZGVmYXVsdCIsImlpZCI6Imluc18ycEpJRm1YSTJsTUlJcU9BZGVoTzFyNndmWUYiLCJyaWQiOiJ1c2VyXzJwamd0Y0oyR1lqZDVldUt3d0tNb01XMHRkZyJ9"; // Use Clerk's default avatar as fallback
        }}
      />
      <form onSubmit={handleSubmit} className="flex-1">
        <input
          type="text"
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          onFocus={() => setIsInputFocused(true)}
          placeholder="Add a comment..."
          className="w-full px-0 py-1 border-b border-gray-200 focus:border-b-2 focus:border-gray-800 outline-none text-base"
        />
        {isInputFocused && (
          <div className="flex justify-end gap-2 mt-2">
            <button
              type="button"
              onClick={() => {
                setCommentText("");
                setIsInputFocused(false);
              }}
              className="px-4 py-2 rounded-full hover:bg-gray-100 text-sm font-medium"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!commentText.trim()}
              className="px-4 py-2 rounded-full bg-gray-100 text-gray-500 hover:bg-gray-200 text-sm font-medium disabled:bg-gray-100 disabled:text-gray-400"
            >
              Comment
            </button>
          </div>
        )}
      </form>

      <SignInPrompt
        isOpen={showSignInPrompt}
        onClose={() => setShowSignInPrompt(false)}
        action="comment"
      />
    </div>
  );
}

export default AddComment;
