import { useUser } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import api from "../config/axios";

export const useChannel = () => {
  const { user, isLoaded: isUserLoaded } = useUser();

  const { data: channelData, isLoading } = useQuery({
    queryKey: ["channel", user?.id],
    queryFn: async () => {
      if (!user) return null;

      try {
        const response = await api.get("/users/me/channel");
        return response.data;
      } catch (error) {
        console.error("Channel request error:", error);
        if (error.response?.status === 404) {
          return null;
        }
        throw error;
      }
    },
    enabled: isUserLoaded && !!user,
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 10 * 60 * 1000, // 10 minutes
    retry: false,
  });

  return {
    hasChannel: !!channelData,
    channel: channelData,
    isLoading: isLoading && isUserLoaded,
  };
};
