import React, { useState } from "react";
import { X } from "lucide-react";
import { useToast } from "../shared/Toast";
import { IoInformationCircleOutline } from "react-icons/io5";
import { SignInButton, useAuth } from "@clerk/clerk-react";
import api from "../../config/axios";

const ChannelReportModal = ({ isOpen, onClose, channelHandle }) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSignInPrompt, setShowSignInPrompt] = useState(false);
  const { showToast, ToastComponent } = useToast();
  const { isSignedIn } = useAuth();

  const reportReasons = [
    {
      value: "Harassment and cyberbullying",
      label: "Harassment and cyberbullying",
      info: true,
    },
    {
      value: "Privacy",
      label: "Privacy",
      info: true,
    },
    {
      value: "Impersonation",
      label: "Impersonation",
      info: true,
    },
    {
      value: "Violent threats",
      label: "Violent threats",
      info: true,
    },
    {
      value: "Child endangerment",
      label: "Child endangerment",
      info: true,
    },
    {
      value: "Hate speech against a protected group",
      label: "Hate speech against a protected group",
      info: true,
    },
    {
      value: "Spam and scams",
      label: "Spam and scams",
      info: true,
    },
    {
      value: "None of these are my issue",
      label: "None of these are my issue",
      info: true,
    },
  ];

  if (!isOpen) return null;
  const handleSubmit = async () => {
    if (!isSignedIn) {
      setShowSignInPrompt(true);
      return;
    }

    if (!selectedReason) {
      showToast("Please select a reason for reporting", "error");
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await api.post(`/channels/@${channelHandle}/report`, {
        reason: selectedReason,
        type: "user", // Add this line
      });

      showToast("Report submitted successfully", "success");
      setTimeout(() => {
        setSelectedReason("");
        onClose();
      }, 500);
    } catch (error) {
      console.error("Report error:", error);
      showToast(
        error.response?.data?.message || "Failed to submit report",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  if (showSignInPrompt) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg max-w-sm w-full mx-4">
          <h3 className="text-lg font-bold mb-2">Sign in to report</h3>
          <p className="text-gray-600 mb-4">Sign in to report this channel</p>
          <div className="flex justify-end gap-2">
            <button
              onClick={() => setShowSignInPrompt(false)}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
            >
              Cancel
            </button>
            <SignInButton mode="modal">
              <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                Sign In
              </button>
            </SignInButton>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black/60 flex items-center justify-center z-[1000]">
      <div className="bg-white w-full max-w-[450px] mx-4 rounded-xl">
        {/* Header */}
        <div className="flex justify-between items-center px-6 py-4 border-b">
          <div>
            <h2 className="text-lg font-medium">Report user</h2>
            <p className="text-sm text-gray-500 mt-1">What is the issue?</p>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 p-2 rounded-full"
          >
            <X size={20} />
          </button>
        </div>

        {/* Content */}
        <div className="px-4 py-2">
          {reportReasons.map((reason) => (
            <label
              key={reason.value}
              className="flex items-center justify-between py-2 px-2 rounded-lg hover:bg-gray-50 cursor-pointer transition-colors"
            >
              <div className="flex items-center">
                <input
                  type="radio"
                  name="reportReason"
                  value={reason.value}
                  checked={selectedReason === reason.value}
                  onChange={(e) => setSelectedReason(e.target.value)}
                  className="w-4 h-4 border-2 border-gray-300 focus:ring-blue-500"
                />
                <span className="ml-3 text-sm text-gray-700">
                  {reason.label}
                </span>
              </div>
              {reason.info && (
                <IoInformationCircleOutline
                  className="text-gray-400 hover:text-gray-600"
                  size={18}
                />
              )}
            </label>
          ))}
        </div>

        {/* Footer */}
        <div className="px-6 py-4 border-t flex justify-end">
          <button
            onClick={handleSubmit}
            disabled={!selectedReason || isSubmitting}
            className="px-6 py-2 text-sm font-medium text-white bg-blue-500 rounded-full hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Next
          </button>
        </div>
      </div>
      {ToastComponent}
    </div>
  );
};

export default ChannelReportModal;
