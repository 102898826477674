import React from "react";
import { Link } from "react-router-dom";
import {
  formatViewCount,
  timeAgo,
  formatDuration,
} from "../../utils/formatters"; // Import your formatters
import { BiDotsVertical } from "react-icons/bi";

function HorizontalVideoCard({ video, watchProgress }) {
  return (
    <div className="flex flex-col w-[200px]">
      {" "}
      {/* Reduced from 360px to 200px */}
      <Link to={`/video/${video._id}`} className="relative w-full">
        {/* Thumbnail */}
        <div className="relative">
          <img
            src={video.thumbnailUrl}
            alt={video.title}
            className="w-full aspect-video object-cover rounded-lg" // Changed to rounded-lg
          />
          <span className="absolute bottom-1 right-1 bg-black bg-opacity-80 text-white text-[10px] px-1 rounded">
            {" "}
            {/* Smaller text */}
            {formatDuration(video.duration)}
          </span>
          {/* Progress Bar */}
          {watchProgress && watchProgress.lastPosition > 0 && (
            <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-800 bg-opacity-60">
              <div
                className="h-full"
                style={{
                  width: `${
                    (watchProgress.lastPosition / video.duration) * 100
                  }%`,
                  backgroundColor: "#9147ff",
                }}
              />
            </div>
          )}
        </div>
      </Link>
      {/* Info Section - Reduced text sizes and spacing */}
      <div className="mt-2">
        {" "}
        {/* Reduced from mt-3 */}
        <Link to={`/video/${video._id}`}>
          <h3 className="text-xs font-medium line-clamp-2 leading-4">
            {" "}
            {/* Smaller text and line height */}
            {video.title}
          </h3>
        </Link>
        <Link
          to={`/channel/${video.channelId?.handle}`}
          className="text-[11px] text-gray-600 hover:text-gray-900 block mt-1"
        >
          {video.channelId?.name}
        </Link>
        <div className="text-[11px] text-gray-600 flex items-center mt-0.5">
          <span>{formatViewCount(video.views)} views</span>
          <span className="mx-1">•</span>
          <span>{timeAgo(video.createdAt)}</span>
        </div>
      </div>
    </div>
  );
}

export default HorizontalVideoCard;
