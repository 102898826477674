import api from "../config/axios";

export const getWatchProgress = async (videos, isSignedIn) => {
  if (!isSignedIn || !videos.length) {
    // console.log("getWatchProgress: Not signed in or no videos. Returning empty object."); // Log when not signed in or no videos
    return {};
  }

  try {
    const progressPromises = videos.map((video) => {
      /*       console.log(
        "getWatchProgress: Fetching progress for videoId:",
        video._id
      ); // Log before fetching */
      return api
        .get(`/history/progress/${video._id}`)
        .then((res) => {
          /*           console.log(
            "getWatchProgress: Response for videoId:",
            video._id,
            "data:",
            res.data.data
          ); // Log the response data */
          return {
            videoId: video._id,
            progress: res.data.data,
          };
        })
        .catch((error) => {
          console.error(
            "getWatchProgress: Error fetching progress for videoId:",
            video._id,
            error
          ); // Log the error
          return { videoId: video._id, progress: null };
        });
    });

    const progressResults = await Promise.all(progressPromises);
    const progressObject = Object.fromEntries(
      progressResults.map(({ videoId, progress }) => [videoId, progress])
    );
    //console.log("getWatchProgress: Final progress object:", progressObject); // Log the final progress object
    return progressObject;
  } catch (error) {
    console.error("Error fetching watch progress:", error);
    return {};
  }
};
