import React, { useEffect, useState } from "react"; // Add useState
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "@clerk/clerk-react"; // Add this for isSignedIn
import HorizontalVideoCard from "../../../components/shared/HorizontalVideoCard";
import {
  setHistory,
  setError,
  setLoading,
} from "../../../redux/slices/watchHistorySlice";
import api from "../../../config/axios";

function WatchHistoryPreview() {
  const dispatch = useDispatch();
  const { isSignedIn } = useAuth(); // Get isSignedIn from Clerk
  const { history, loading } = useSelector((state) => state.watchHistory);
  const [videosWithProgress, setVideosWithProgress] = useState([]); // Add state for videos

  // First useEffect to get history
  useEffect(() => {
    const getHistory = async () => {
      try {
        dispatch(setLoading(true));
        // Only fetch first 5 items for preview
        const response = await api.get("/history?page=1&limit=5");
        const historyData = response.data.data;

        // If we have history items, fetch their progress
        if (historyData.length > 0 && isSignedIn) {
          const progressPromises = historyData.map((item) =>
            api
              .get(`/history/progress/${item.videoId._id}`)
              .then((res) => ({
                videoId: item.videoId._id,
                progress: res.data.data,
              }))
              .catch(() => ({ videoId: item.videoId._id, progress: null }))
          );

          const progressResults = await Promise.all(progressPromises);
          const progressMap = Object.fromEntries(
            progressResults.map(({ videoId, progress }) => [videoId, progress])
          );

          // Add progress to history items
          const historyWithProgress = historyData.map((item) => ({
            ...item,
            watchProgress: progressMap[item.videoId._id],
          }));

          dispatch(setHistory(historyWithProgress));
        } else {
          dispatch(setHistory(historyData));
        }
      } catch (error) {
        dispatch(setError(error.message));
      } finally {
        dispatch(setLoading(false));
      }
    };

    if (isSignedIn) {
      getHistory();
    }
  }, [dispatch, isSignedIn]);

  if (loading) return null;

  return (
    <div className="mb-8">
      <div className="flex items-center justify-between px-8 mb-4">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-medium">Watch history</h2>
          {history?.length > 0 && (
            <span className="text-sm text-gray-600">
              {history.length} recent{" "}
              {history.length === 1 ? "video" : "videos"}
            </span>
          )}
        </div>
        <Link
          to="/history"
          className="text-sm font-medium hover:bg-gray-100 px-3 py-1 rounded-full"
        >
          View all
        </Link>
      </div>
      <div className="px-8">
        {history.length === 0 ? (
          <div className="text-gray-500">No watch history yet</div>
        ) : (
          <div className="flex gap-3 overflow-x-auto pb-4 -mx-2">
            {" "}
            {/* Reduced gap from 4 to 3, added negative margin */}
            {history.slice(0, 5).map((item) => (
              <div key={item._id} className="flex-shrink-0">
                {" "}
                {/* Removed fixed width container */}
                <HorizontalVideoCard
                  video={item.videoId}
                  watchedAt={item.watchedAt}
                  watchProgress={item.watchProgress}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default WatchHistoryPreview;
