import { createSlice } from "@reduxjs/toolkit";

const commentSlice = createSlice({
  name: "comments",
  initialState: {
    comments: [],
    loading: false,
    error: null,
  },
  reducers: {
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    addComment: (state, action) => {
      state.comments.unshift(action.payload);
    },
    updateCommentInteraction: (state, action) => {
      const updateComment = (comments, commentId) => {
        for (let comment of comments) {
          if (comment._id === commentId) {
            comment.likes = action.payload.likes;
            comment.dislikes = action.payload.dislikes;
            return true;
          }
          if (comment.replies?.length > 0) {
            if (updateComment(comment.replies, commentId)) return true;
          }
        }
        return false;
      };
      updateComment(state.comments, action.payload.commentId);
    },
    addReply: (state, action) => {
      const { parentId, reply } = action.payload;
      const findAndAddReply = (comments) => {
        for (let comment of comments) {
          if (comment._id === parentId) {
            if (!comment.replies) comment.replies = [];
            comment.replies.push(reply);
            return true;
          }
        }
        return false;
      };
      findAndAddReply(state.comments);
    },
  },
});

export const { setComments, addComment, updateCommentInteraction, addReply } =
  commentSlice.actions;
export default commentSlice.reducer;
