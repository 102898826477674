import React, { useEffect, useState } from "react";
import VideoCard from "../components/shared/VideoCard";
import { useAuth } from "@clerk/clerk-react";
import { getWatchProgress } from "../utils/videoProgress";
import api from "../config/axios";
import { Link } from "react-router-dom";
import { formatDuration, formatViewCount, timeAgo } from "../utils/formatters";

function TrendingPage() {
  const { isSignedIn } = useAuth();
  const [videoProgress, setVideoProgress] = useState({});
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTrendingVideos = async () => {
      try {
        setLoading(true);
        const response = await api.get("/videos/trending");

        if (!response.data || response.data.length === 0) {
          setVideos([]);
          return;
        }

        setVideos(response.data);

        if (isSignedIn) {
          const progress = await getWatchProgress(response.data, isSignedIn);
          setVideoProgress(progress);
        }
      } catch (err) {
        setError(
          err.response?.data?.message || "Failed to fetch trending videos"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchTrendingVideos();
  }, [isSignedIn]);

  if (loading) {
    return (
      <div className="px-8 py-6">
        <h1 className="text-2xl font-bold mb-6">Trending</h1>
        <div className="flex gap-4 mb-6 border-b">
          <button className="px-4 py-2 text-[#9147ff] border-b-2 border-[#9147ff] font-medium">
            Now
          </button>
        </div>
        <div className="space-y-4">
          {[...Array(12)].map((_, i) => (
            <div key={i} className="animate-pulse flex gap-4">
              <div className="bg-gray-200 w-64 h-36 rounded-lg" />
              <div className="flex-1">
                <div className="bg-gray-200 h-5 w-3/4 mb-3" />
                <div className="bg-gray-200 h-4 w-1/2 mb-2" />
                <div className="bg-gray-200 h-4 w-1/4" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="px-8 py-6">
        <h1 className="text-2xl font-bold mb-6">Trending</h1>
        <div className="flex gap-4 mb-6 border-b">
          <button className="px-4 py-2 text-[#9147ff] border-b-2 border-[#9147ff] font-medium">
            Now
          </button>
        </div>
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
          <p>{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-2 text-sm underline hover:no-underline"
          >
            Try again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="px-8 py-6">
      <h1 className="text-2xl font-bold mb-6">Trending</h1>

      <div className="flex gap-4 mb-6 border-b">
        <button className="px-4 py-2 text-[#9147ff] border-b-2 border-[#9147ff] font-medium transition-colors duration-200">
          Now
        </button>
      </div>

      {!videos.length ? (
        <div className="text-center py-12">
          <p className="text-gray-500">
            No trending videos available right now.
          </p>
        </div>
      ) : (
        <div className="space-y-4">
          {videos.map((video) => (
            <div
              key={video._id}
              className="flex gap-4 group hover:bg-gray-50 p-2 rounded-lg"
            >
              <Link
                to={`/video/${video._id}`}
                className="relative flex-shrink-0"
              >
                <img
                  src={video.thumbnailUrl}
                  alt={video.title}
                  className="w-64 h-36 object-cover rounded-lg"
                />
                <span className="absolute bottom-1 right-1 bg-black bg-opacity-80 text-white text-xs px-1 rounded">
                  {formatDuration(video.duration)}
                </span>
                {videoProgress[video._id] > 0 && (
                  <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-800 bg-opacity-60">
                    <div
                      className="h-full bg-[#9147ff]"
                      style={{
                        width: `${Math.min(
                          (videoProgress[video._id] / video.duration) * 100,
                          100
                        )}%`,
                      }}
                    />
                  </div>
                )}
              </Link>

              <div className="flex-1 min-w-0">
                <Link to={`/video/${video._id}`}>
                  <h3 className="text-base font-medium mb-1 line-clamp-2 hover:text-[#9147ff]">
                    {video.title}
                  </h3>
                </Link>

                <Link
                  to={`/channel/${video.channelId?.handle}`}
                  className="text-sm text-gray-600 hover:text-gray-900 block"
                >
                  {video.channelId?.name}
                </Link>

                <div className="text-sm text-gray-600 mt-1">
                  <span>{formatViewCount(video.views)} views</span>
                  <span className="mx-1">•</span>
                  <span>{timeAgo(video.createdAt)}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default TrendingPage;
