// slices/watchHistorySlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  history: [],
  loading: false,
  error: null,
  pagination: {
    page: 1,
    limit: 20,
    total: 0,
    pages: 0,
  },
};

const watchHistorySlice = createSlice({
  name: "watchHistory",
  initialState,
  reducers: {
    setHistory: (state, action) => {
      state.history = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    addToHistory: (state, action) => {
      const existingIndex = state.history.findIndex(
        (item) => item.videoId._id === action.payload.videoId
      );
      if (existingIndex !== -1) {
        state.history[existingIndex] = action.payload;
      } else {
        state.history.unshift(action.payload);
      }
    },
    removeFromHistory: (state, action) => {
      state.history = state.history.filter(
        (item) => item.videoId._id !== action.payload
      );
      state.pagination.total -= 1;
    },
    clearHistory: (state) => {
      state.history = [];
      state.pagination = {
        ...state.pagination,
        total: 0,
        pages: 0,
      };
    },
  },
});

export const {
  setHistory,
  setLoading,
  setError,
  setPagination,
  addToHistory,
  removeFromHistory,
  clearHistory,
} = watchHistorySlice.actions;

export default watchHistorySlice.reducer;
