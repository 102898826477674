import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth } from "@clerk/clerk-react";
import { setIsSubscribed } from "../../redux/slices/channelSlice";
import {
  addSubscription,
  removeSubscription,
} from "../../redux/slices/subscriptionSlice";

import api from "../../config/axios";

// SubscribeButton.jsx
const SubscribeButton = ({
  channelHandle,
  channelId,
  onSubscriptionChange,
}) => {
  const dispatch = useDispatch();
  const { isSignedIn } = useAuth();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const subscribedChannels = useSelector(
    (state) => state.subscriptions.subscribedChannels
  );
  const isSubscribed = subscribedChannels.includes(channelId);
  const [loading, setLoading] = useState(false);

  const handleSubscribe = async () => {
    if (!isSignedIn || !isAuthenticated) {
      onSubscriptionChange?.("signin-required");
      return;
    }

    try {
      setLoading(true);
      console.log("Current subscription state:", {
        channelId,
        isSubscribed,
        subscribedChannels,
      });

      const response = await api[isSubscribed ? "delete" : "post"](
        `/channels/@${channelHandle}/subscribe`
      );

      if (response.data.message === "Already subscribed") {
        // If already subscribed, make sure our local state reflects this
        dispatch(addSubscription(channelId));
      } else if (response.data.message === "Not subscribed") {
        dispatch(removeSubscription(channelId));
      } else {
        // Normal subscription/unsubscription flow
        if (isSubscribed) {
          dispatch(removeSubscription(channelId));
        } else {
          dispatch(addSubscription(channelId));
        }
      }

      onSubscriptionChange?.({
        isSubscribed: !isSubscribed,
        subscriberCount: response.data.subscriberCount,
      });
    } catch (err) {
      console.error("Subscription error details:", err.response?.data);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      onClick={handleSubscribe}
      disabled={loading}
      className={`px-4 py-2 rounded-full ${
        isSubscribed
          ? "bg-purple-600 hover:bg-purple-700 text-white"
          : "bg-gray-100 hover:bg-gray-200 text-gray-900"
      }`}
    >
      {loading ? "Loading..." : isSubscribed ? "Subscribed" : "Subscribe"}
    </button>
  );
};

export default SubscribeButton;
