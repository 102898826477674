// Search.jsx
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import api from "../../config/axios";
import { useAuth } from "@clerk/clerk-react";
import { getWatchProgress } from "../../utils/videoProgress";
import SearchCard from "../../components/search/SearchCard";
import ChannelCard from "../../components/search/ChannelCard";

function Search({ isSidebarOpen }) {
  const { isSignedIn } = useAuth();
  const [videoProgress, setVideoProgress] = useState({});

  const [searchParams] = useSearchParams();
  const [results, setResults] = useState({ channels: [], videos: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const query = searchParams.get("q");

  const updateChannelData = (updatedChannel) => {
    setResults((prev) => ({
      ...prev,
      channels: prev.channels.map((channel) =>
        channel._id === updatedChannel._id ? updatedChannel : channel
      ),
    }));
  };

  useEffect(() => {
    const fetchResults = async () => {
      if (!query) {
        setResults({ channels: [], videos: [] });
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        const response = await api.get(
          `/search?q=${encodeURIComponent(query)}`
        );
        setResults(response.data);

        // Get watch progress for search results videos
        if (response.data.videos?.length > 0) {
          const progress = await getWatchProgress(
            response.data.videos,
            isSignedIn
          );
          setVideoProgress(progress);
        }
      } catch (err) {
        console.error("Search error:", err);
        setError("Failed to load search results");
      } finally {
        setIsLoading(false);
      }
    };

    fetchResults();
  }, [query, isSignedIn]);

  //console.log(results);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen text-red-500">
        {error}
      </div>
    );
  }

  return (
    <div
      className={`max-w-[1096px] mx-auto ${
        isSidebarOpen ? "pl-64" : "pl-20"
      } transition-all duration-200`}
    >
      <div className="py-6 px-6">
        {results.channels?.length > 0 && (
          <div className="mb-8">
            {results.channels.map((channel) => (
              <ChannelCard
                key={channel._id}
                channel={channel}
                onChannelUpdate={updateChannelData} // Add this prop
              />
            ))}
          </div>
        )}

        {/* Videos Section */}
        <div className="flex flex-col gap-4">
          {results.videos?.map((video) => (
            <SearchCard
              key={video._id}
              video={video}
              watchProgress={videoProgress[video._id]}
            />
          ))}
        </div>

        {/* No Results */}
        {!results.channels?.length && !results.videos?.length && (
          <div className="text-center text-gray-500 mt-8">
            No results found for "{query}"
          </div>
        )}
      </div>
    </div>
  );
}

export default Search;
