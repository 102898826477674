import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "@clerk/clerk-react";
import HorizontalVideoCard from "../../../components/shared/HorizontalVideoCard";
import { getWatchProgress } from "../../../utils/videoProgress";
import { setLikedVideos, setLoading } from "../../../redux/slices/videoSlice";
import api from "../../../config/axios";

function LikedVideosPreview() {
  const dispatch = useDispatch();
  const { likedVideos, loading } = useSelector((state) => state.videos);
  const { isSignedIn } = useAuth();
  const [videoProgress, setVideoProgress] = useState({});

  useEffect(() => {
    const fetchLikedVideos = async () => {
      try {
        const response = await api.get("/users/me/liked");
        dispatch(setLikedVideos(response.data));

        // Get watch progress
        const progress = await getWatchProgress(response.data, isSignedIn);
        setVideoProgress(progress);
      } catch (err) {
        console.error(err);
      }
    };

    fetchLikedVideos();
  }, [dispatch, isSignedIn]);

  if (loading) return null;

  // LikedVideosPreview.jsx
  return (
    <div className="mb-8">
      <div className="flex items-center justify-between px-8 mb-4">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-medium">Liked videos</h2>
          {likedVideos?.length > 0 && (
            <span className="text-sm text-gray-600">
              {likedVideos.length} video{likedVideos.length !== 1 ? "s" : ""}
            </span>
          )}
        </div>
        {likedVideos?.length > 0 && (
          <Link
            to="/liked-videos"
            className="text-sm font-medium px-3 py-1 hover:bg-gray-100 rounded-full"
          >
            View all
          </Link>
        )}
      </div>

      <div className="px-8">
        {likedVideos.length === 0 ? (
          <div className="text-gray-500">No liked videos yet</div>
        ) : (
          <div className="flex gap-4 overflow-x-auto pb-4">
            {" "}
            {/* Changed to horizontal layout */}
            {likedVideos.slice(0, 5).map((video) => (
              <div key={video._id} className="flex-shrink-0">
                {" "}
                {/* Added flex-shrink-0 */}
                <HorizontalVideoCard
                  video={video}
                  watchProgress={videoProgress[video._id]}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default LikedVideosPreview;
