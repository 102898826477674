import React, { useState } from "react";
import { X, Mail, ChevronRight, ChevronLeft } from "lucide-react";
import {
  FaWhatsapp,
  FaFacebook,
  FaXTwitter,
  FaDiscord,
  FaReddit,
  FaInstagram,
} from "react-icons/fa6";
import { useToast } from "../shared/Toast";

const ShareModal = ({ isOpen, onClose, currentVideo, channelInfo }) => {
  const [copied, setCopied] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const { showToast, ToastComponent } = useToast();

  if (!isOpen) return null;

  const videoUrl = `https://yivster.com/video/${currentVideo._id}`;

  const sharePlatforms = [
    {
      name: "WhatsApp",
      icon: <FaWhatsapp className="w-6 h-6 text-[#25D366]" />,
      shareUrl: `https://wa.me/?text=${encodeURIComponent(videoUrl)}`,
    },
    {
      name: "X",
      icon: <FaXTwitter className="w-6 h-6" />,
      shareUrl: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        videoUrl
      )}`,
    },
    {
      name: "Discord",
      icon: <FaDiscord className="w-6 h-6 text-[#5865F2]" />,
      shareUrl: `https://discord.com/channels/@me?message=${encodeURIComponent(
        videoUrl
      )}`,
    },
    {
      name: "Reddit",
      icon: <FaReddit className="w-6 h-6 text-[#FF4500]" />,
      shareUrl: `https://www.reddit.com/submit?url=${encodeURIComponent(
        videoUrl
      )}`,
    },
    {
      name: "Facebook",
      icon: <FaFacebook className="w-6 h-6 text-[#1877F2]" />,
      shareUrl: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        videoUrl
      )}`,
    },
    {
      name: "Instagram",
      icon: <FaInstagram className="w-6 h-6 text-[#E4405F]" />,
      shareUrl: `https://www.instagram.com/add?url=${encodeURIComponent(
        videoUrl
      )}`,
    },
    {
      name: "Email",
      icon: <Mail className="w-6 h-6 text-gray-600" />,
      shareUrl: `mailto:?body=${encodeURIComponent(videoUrl)}`,
    },
  ];

  const visiblePlatforms = sharePlatforms.slice(startIndex, startIndex + 5);
  const canScrollLeft = startIndex > 0;
  const canScrollRight = startIndex + 5 < sharePlatforms.length;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(videoUrl);
      setCopied(true);
      showToast("Link copied to clipboard!");
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      showToast("Failed to copy link", "error");
    }
  };

  const handleShare = (shareUrl) => {
    window.open(shareUrl, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <div
        className="fixed inset-0 bg-black/60 flex items-center justify-center z-50"
        onClick={onClose}
      >
        <div
          className="bg-white rounded-lg p-6 w-[450px] relative"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium">Share</h3>
            <button
              onClick={onClose}
              className="p-1 hover:bg-gray-100 rounded-full"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {/* Share platforms with navigation buttons */}
          <div className="relative mb-6">
            {canScrollLeft && (
              <button
                onClick={() => setStartIndex((prev) => Math.max(0, prev - 1))}
                className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-3 p-1 bg-white shadow-md rounded-full hover:bg-gray-100"
              >
                <ChevronLeft className="w-5 h-5" />
              </button>
            )}

            <div className="flex justify-center gap-6 py-2 px-4">
              {visiblePlatforms.map((platform) => (
                <button
                  key={platform.name}
                  onClick={() => handleShare(platform.shareUrl)}
                  className="flex flex-col items-center gap-2 min-w-[64px] hover:opacity-80"
                >
                  {platform.icon}
                  <span className="text-xs">{platform.name}</span>
                </button>
              ))}
            </div>

            {canScrollRight && (
              <button
                onClick={() => setStartIndex((prev) => prev + 1)}
                className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-3 p-1 bg-white shadow-md rounded-full hover:bg-gray-100"
              >
                <ChevronRight className="w-5 h-5" />
              </button>
            )}
          </div>

          <div className="flex gap-2 bg-gray-100 p-2 rounded-lg">
            <input
              type="text"
              value={videoUrl}
              readOnly
              className="flex-1 bg-transparent outline-none"
            />
            <button
              onClick={handleCopy}
              className={`px-4 py-1 rounded-full text-sm transition-colors ${
                copied
                  ? "bg-green-500 text-white"
                  : "bg-blue-500 text-white hover:bg-blue-600"
              }`}
            >
              {copied ? "Copied!" : "Copy"}
            </button>
          </div>
        </div>
      </div>
      {ToastComponent}
    </>
  );
};

export default ShareModal;
