import { configureStore } from "@reduxjs/toolkit";
import videoReducer from "./slices/videoSlice";
import userReducer from "./slices/userSlice";
import searchReducer from "./slices/searchSlice";
import channelReducer from "./slices/channelSlice";
import authReducer from "./slices/authSlice";
import commentReducer from "./slices/commentSlice";
import stripeReducer from "./slices/stripeSlice";
import subscriptionReducer from "./slices/subscriptionSlice";
import watchHistoryReducer from "./slices/watchHistorySlice";

export const store = configureStore({
  reducer: {
    videos: videoReducer,
    user: userReducer,
    search: searchReducer,
    channel: channelReducer,
    auth: authReducer,
    comments: commentReducer,
    stripe: stripeReducer,
    subscriptions: subscriptionReducer,
    watchHistory: watchHistoryReducer,
  },
});
