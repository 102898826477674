import { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {
  setStripeLoading,
  setStripeError,
} from "../../redux/slices/stripeSlice";
import toast from "react-hot-toast";
import { useUser } from "@clerk/clerk-react";
import api from "../../config/axios";
import { X } from "lucide-react"; // Import X icon for close button

const TipModal = ({ isOpen, onClose, channelInfo, currentVideo }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useUser();
  const isProcessing = useSelector((state) => state.stripe.loading);
  const error = useSelector((state) => state.stripe.error);
  const [clientSecret, setClientSecret] = useState("");
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    // Ensure Stripe and Elements are loaded
    if (!stripe || !elements) {
      return;
    }

    if (selectedAmount) {
      createPaymentIntent(selectedAmount);
    }
  }, [selectedAmount]);

  // Handle clicking outside the modal to close
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const createPaymentIntent = async (amount) => {
    try {
      dispatch(setStripeLoading(true));
      const paymentResponse = await api.post("/stripe/create-payment-intent", {
        amount,
        channelId: channelInfo._id,
      });
      setClientSecret(paymentResponse.data.clientSecret);
    } catch (error) {
      console.error("Error creating payment intent:", error);
      toast.error("Failed to initialize payment");
      dispatch(setStripeError(error.response?.data?.message || error.message));
    } finally {
      dispatch(setStripeLoading(false));
    }
  };

  const handleTip = async (e) => {
    e.preventDefault();

    if (!stripe || !elements || !clientSecret) {
      return;
    }

    try {
      dispatch(setStripeLoading(true));
      dispatch(setStripeError(null));

      const { error: submitError } = await elements.submit();
      if (submitError) {
        throw submitError;
      }

      const { error: confirmError, paymentIntent } =
        await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url: window.location.href,
            payment_method_data: {
              billing_details: {
                name: user.fullName,
                email: user.primaryEmailAddress.emailAddress,
              },
            },
          },
          redirect: "if_required",
        });

      if (confirmError) {
        throw confirmError;
      }

      if (paymentIntent.status === "succeeded") {
        // Record the tip in your backend
        await api.post("/tips", {
          amount: selectedAmount,
          videoId: currentVideo._id,
          stripePaymentIntentId: paymentIntent.id,
        });

        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
          onClose();
        }, 2000);
      }
    } catch (error) {
      console.error("Error processing tip:", error);
      dispatch(setStripeError(error.message));
      toast.error(error.message || "Failed to process tip");
    } finally {
      dispatch(setStripeLoading(false));
    }
  };

  if (!stripe || !elements) {
    return <div>Loading payment...</div>;
  }

  if (showSuccess) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-8 rounded-lg max-w-md w-full mx-4 text-center">
          <div className="mb-4 text-green-500">
            <svg
              className="mx-auto h-12 w-12"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <h3 className="text-xl font-bold mb-2">Thank You!</h3>
          <p className="text-gray-600">
            Successfully sent ${selectedAmount} tip to {channelInfo.name}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleBackdropClick}
    >
      <div className="bg-white p-6 rounded-lg max-w-md w-full mx-4 relative">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-1 hover:bg-gray-100 rounded-full"
          aria-label="Close modal"
        >
          <X size={20} />
        </button>

        <h3 className="text-lg font-bold mb-6">
          Send A Tip To {channelInfo.name}
        </h3>

        {error && (
          <div className="mb-4 p-2 bg-red-100 text-red-600 rounded">
            {error}
          </div>
        )}

        {/* Enhanced amount buttons */}
        <div className="grid grid-cols-3 gap-3 mb-6">
          {[1, 5, 10, 20, 50, 100].map((amount) => (
            <button
              key={amount}
              onClick={() => setSelectedAmount(amount)}
              className={`
                py-3 px-4 rounded-full text-center transition-all
                ${
                  selectedAmount === amount
                    ? "bg-purple-100 border-2 border-purple-500 text-purple-700 font-medium"
                    : "bg-gray-50 border border-gray-200 hover:bg-gray-100"
                }
                ${isProcessing ? "opacity-50 cursor-not-allowed" : ""}
              `}
              disabled={isProcessing}
            >
              ${amount}
            </button>
          ))}
        </div>

        {clientSecret && (
          <form onSubmit={handleTip}>
            <PaymentElement options={{ layout: "tabs" }} className="mb-6" />
            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                disabled={isProcessing}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={isProcessing || !stripe || !elements}
              >
                {isProcessing ? "Processing..." : `Send $${selectedAmount} Tip`}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default TipModal;
