// components/channel/AboutModal.jsx
import React, { useState } from "react";
import { X, Share2 } from "lucide-react";
import { Flag } from "lucide-react"; // Add this import
import ShareModal from "./ShareModal";
import ChannelReportModal from "./ChannelReportModal"; // Add this import
import { useToast } from "../shared/Toast";
import { SignInButton, useAuth } from "@clerk/clerk-react";
import {
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaGithub,
  FaGlobe,
  FaDiscord,
  FaYoutube,
  FaTwitch,
} from "react-icons/fa6";
import api from "../../config/axios";

const getPlatformIcon = (platform) => {
  const icons = {
    twitter: <FaTwitter className="w-4 h-4 text-[#1DA1F2]" />,
    facebook: <FaFacebook className="w-4 h-4 text-[#1877F2]" />,
    instagram: <FaInstagram className="w-4 h-4 text-[#E4405F]" />,
    github: <FaGithub className="w-4 h-4" />,
    discord: <FaDiscord className="w-4 h-4 text-[#5865F2]" />,
    youtube: <FaYoutube className="w-4 h-4 text-[#FF0000]" />,
    twitch: <FaTwitch className="w-4 h-4 text-[#9146FF]" />,
    default: <FaGlobe className="w-4 h-4 text-gray-600" />,
  };

  return icons[platform.toLowerCase()] || icons.default;
};

const AboutModal = ({ isOpen, onClose, channelInfo, channelVideos = [] }) => {
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false); // Add this state
  const [showReportModal, setShowReportModal] = useState(false); // Add this state
  const [showSignInPrompt, setShowSignInPrompt] = useState(false); // Add this
  const { isSignedIn } = useAuth(); // Add this
  const { showToast, ToastComponent } = useToast();

  if (!isOpen) return null;

  const handleCopyChannelId = async () => {
    try {
      await navigator.clipboard.writeText(channelInfo._id); // Changed from .id to ._id
      showToast("Channel ID copied to clipboard!");
      setShowShareDropdown(false);
    } catch (err) {
      showToast("Failed to copy channel ID", "error");
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-black/60 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto">
          {/* Header */}
          <div className="flex items-center justify-between p-6 border-b">
            <h2 className="text-xl font-medium">About</h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          {/* Content */}
          <div className="p-6 space-y-6">
            {/* Description */}
            <div className="whitespace-pre-wrap">
              {channelInfo?.description}
            </div>

            {/* Links */}
            {channelInfo?.socialLinks &&
              Object.keys(channelInfo.socialLinks).length > 0 && (
                <div>
                  <h3 className="text-base font-medium mb-2">Links</h3>
                  <div className="space-y-3">
                    {Object.entries(channelInfo.socialLinks || {}).map(
                      ([platform, url]) => (
                        <a
                          key={platform}
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
                        >
                          {getPlatformIcon(platform)}
                          <span>{url}</span>
                        </a>
                      )
                    )}
                  </div>
                </div>
              )}

            {/* Channel details */}
            <div>
              <h3 className="text-base font-medium mb-2">Channel details</h3>
              <div className="space-y-4 text-sm">
                <div className="flex items-center gap-3">
                  <span>@{channelInfo?.handle}</span>
                </div>

                <div className="flex items-center gap-3">
                  <span>{channelInfo?.totalViews?.toLocaleString()} views</span>
                </div>

                <div className="flex items-center gap-3">
                  <span>
                    {channelInfo?.subscribers?.toLocaleString()} subscribers
                  </span>
                </div>

                <div className="flex items-center gap-3">
                  <span>{channelVideos?.length || 0} videos</span>
                </div>

                <div className="flex items-center gap-3">
                  <span>Joined {channelInfo?.joinedDate}</span>
                </div>

                {channelInfo?.location && (
                  <div className="flex items-center gap-3">
                    <span>{channelInfo.location}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Footer */}
          {/* Footer */}
          <div className="p-4 flex gap-2 border-t">
            <div className="relative">
              <button
                onClick={() => setShowShareDropdown(!showShareDropdown)}
                className="px-4 py-2 rounded-full flex items-center gap-2 hover:bg-gray-100"
              >
                <Share2 className="w-5 h-5" />
                Share channel
              </button>
              {showShareDropdown && (
                <div className="absolute bottom-full left-0 mb-2 bg-white rounded-lg shadow-lg border py-2 min-w-[200px]">
                  <button
                    onClick={() => {
                      setShowShareModal(true);
                      setShowShareDropdown(false);
                    }}
                    className="w-full px-4 py-2 text-left hover:bg-gray-100 flex items-center gap-2"
                  >
                    <Share2 className="w-4 h-4" />
                    Share channel
                  </button>
                  <button
                    onClick={handleCopyChannelId}
                    className="w-full px-4 py-2 text-left hover:bg-gray-100"
                  >
                    Copy channel ID
                  </button>
                </div>
              )}
            </div>
            <div className="relative">
              <button
                onClick={() => setShowMenu(true)}
                className="px-4 py-2 rounded-full flex items-center gap-2 hover:bg-gray-100"
              >
                <Flag className="w-5 h-5" />
                Report user
              </button>
              {showMenu && (
                <div className="absolute bottom-full right-0 mb-2 bg-white rounded-lg shadow-lg border py-2 min-w-[200px]">
                  <button
                    onClick={async () => {
                      if (!isSignedIn) {
                        setShowSignInPrompt(true);
                        setShowMenu(false); // Add this to close menu when showing sign in
                        return;
                      }
                      try {
                        await api.post(
                          `/channels/@${channelInfo.handle}/report`,
                          {
                            reason: "Inappropriate channel art",
                            type: "channel_art", // This needs to match your enum values
                          }
                        );
                        showToast("Channel art reported successfully");
                        setShowMenu(false);
                      } catch (error) {
                        showToast(
                          error.response?.data?.message ||
                            "Failed to submit report"
                        );
                      }
                    }}
                    className="w-full px-4 py-2 text-left hover:bg-gray-100 text-sm"
                  >
                    Report channel art
                  </button>
                  <button
                    onClick={async () => {
                      if (!isSignedIn) {
                        setShowSignInPrompt(true);
                        setShowMenu(false); // Add this to close menu when showing sign in
                        return;
                      }
                      try {
                        await api.post(
                          `/channels/@${channelInfo.handle}/report`,
                          {
                            reason: "Inappropriate profile picture",
                            type: "profile_picture", // This needs to match your enum values
                          }
                        );
                        showToast("Profile picture reported successfully");
                        setShowMenu(false);
                      } catch (error) {
                        showToast(
                          error.response?.data?.message ||
                            "Failed to submit report"
                        );
                      }
                    }}
                    className="w-full px-4 py-2 text-left hover:bg-gray-100 text-sm"
                  >
                    Report profile picture
                  </button>
                  <button
                    onClick={() => {
                      setShowReportModal(true);
                      setShowMenu(false);
                    }}
                    className="w-full px-4 py-2 text-left hover:bg-gray-100 text-sm"
                  >
                    Report user
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ShareModal
        isOpen={showShareModal}
        onClose={() => setShowShareModal(false)}
        channelInfo={channelInfo}
      />

      {showReportModal && (
        <ChannelReportModal
          isOpen={showReportModal}
          onClose={() => setShowReportModal(false)}
          channelHandle={channelInfo.handle}
        />
      )}

      {showSignInPrompt && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full mx-4">
            <h3 className="text-lg font-bold mb-2">Sign in to report</h3>
            <p className="text-gray-600 mb-4">Sign in to report this channel</p>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowSignInPrompt(false)}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
              >
                Cancel
              </button>
              <SignInButton mode="modal">
                <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                  Sign In
                </button>
              </SignInButton>
            </div>
          </div>
        </div>
      )}
      {ToastComponent}
    </>
  );
};

export default AboutModal;
