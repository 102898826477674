import React from "react";

const ChannelSkeleton = () => {
  return (
    <div className="animate-pulse">
      {/* Banner */}
      <div className="w-full h-[200px] bg-gray-200 dark:bg-gray-800" />

      {/* Channel Info Section */}
      <div className="px-6">
        <div className="flex flex-col sm:flex-row gap-6 items-start mt-6">
          {/* Avatar */}
          <div className="w-[136px] h-[136px] rounded-full bg-gray-300 dark:bg-gray-700" />

          {/* Info */}
          <div className="flex-1 space-y-4">
            <div className="flex justify-between items-start">
              <div className="space-y-4">
                {/* Channel name */}
                <div className="h-7 w-48 bg-gray-300 dark:bg-gray-700 rounded" />
                {/* Channel stats */}
                <div className="h-4 w-72 bg-gray-200 dark:bg-gray-700 rounded" />
                {/* Channel description */}
                <div className="h-4 w-96 bg-gray-200 dark:bg-gray-700 rounded" />
              </div>
              {/* Subscribe button */}
              <div className="h-10 w-28 bg-gray-300 dark:bg-gray-700 rounded-full" />
            </div>
          </div>
        </div>

        {/* Navigation */}
        <div className="mt-12 border-b border-gray-200 dark:border-gray-700">
          <div className="flex gap-10 overflow-x-auto">
            {/* Nav items */}
            {[
              "HOME",
              "VIDEOS",
              "SHORTS",
              "LIVE",
              "PLAYLISTS",
              "COMMUNITY",
              "CHANNELS",
              "ABOUT",
            ].map((_, idx) => (
              <div
                key={idx}
                className="h-4 w-16 bg-gray-200 dark:bg-gray-700 rounded mb-4"
              />
            ))}
          </div>
        </div>

        {/* Video Grid */}
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {/* Video cards */}
          {[...Array(12)].map((_, idx) => (
            <div key={idx} className="space-y-4">
              {/* Thumbnail */}
              <div className="aspect-video bg-gray-200 dark:bg-gray-700 rounded-xl" />
              {/* Video info */}
              <div className="space-y-2">
                <div className="h-4 w-3/4 bg-gray-200 dark:bg-gray-700 rounded" />
                <div className="h-3 w-1/2 bg-gray-200 dark:bg-gray-700 rounded" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChannelSkeleton;
