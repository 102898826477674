function ShortsPage({ isSidebarOpen }) {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Shorts</h1>
      <p>Shorts content will be implemented soon.</p>
      {/* Future implementation will include short-form video content */}
    </div>
  );
}

export default ShortsPage;
