import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BiFullscreen,
  BiExitFullscreen,
  BiPlay,
  BiPause,
} from "react-icons/bi";
import { HiVolumeUp, HiVolumeOff } from "react-icons/hi";
import { updateVideoInteractions } from "../../redux/slices/videoSlice";
import api from "../../config/axios";

function VideoPlayer({ videoUrl, videoId }) {
  const dispatch = useDispatch();

  // Player state
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [showControls, setShowControls] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Progress tracking state
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [savedPosition, setSavedPosition] = useState(0);
  const [hasLoadedPosition, setHasLoadedPosition] = useState(false);
  const [initialPositionSet, setInitialPositionSet] = useState(false);

  // View tracking state
  const [viewRegistered, setViewRegistered] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const watchTimeRef = useRef(0);
  const lastUpdateRef = useRef(Date.now());
  const hasMetViewingCriteria = useRef(false);

  // Refs
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);

  // Load initial position
  useEffect(() => {
    const loadPosition = async () => {
      if (hasLoadedPosition) return;

      try {
        // console.log("Loading position for:", videoId);
        const response = await api.get(`/history/progress/${videoId}`);
        const data = response?.data?.data;

        if (data && data.lastPosition > 0 && !data.completed) {
          //console.log("Found position:", data.lastPosition);
          setSavedPosition(data.lastPosition);
        }
        setHasLoadedPosition(true);
      } catch (error) {
        //console.error("Error loading position:", error);
        setHasLoadedPosition(true);
      }
    };

    loadPosition();
  }, [videoId, hasLoadedPosition]);

  // Apply saved position when video is ready
  useEffect(() => {
    const video = videoRef.current;
    if (!video || !isVideoReady || savedPosition === 0 || initialPositionSet)
      return;

    // console.log("Video ready, setting position to:", savedPosition);
    video.style.opacity = "0";

    const setPosition = () => {
      video.currentTime = savedPosition;
      setInitialPositionSet(true);

      requestAnimationFrame(() => {
        video.style.opacity = "1";
        video.style.transition = "opacity 0.2s ease-in";
      });
    };

    if (video.readyState >= 2) {
      setPosition();
    } else {
      video.addEventListener("canplay", setPosition, { once: true });
    }

    return () => {
      video.removeEventListener("canplay", setPosition);
    };
  }, [isVideoReady, savedPosition, initialPositionSet]);

  // Handle view counting and progress tracking
  useEffect(() => {
    const video = videoRef.current;
    let progressInterval;

    const updateWatchHistory = async () => {
      if (video.currentTime === 0) return;

      try {
        const data = {
          videoId,
          watchDuration: video.currentTime,
          lastPosition: video.currentTime,
          completed:
            video.ended || Math.abs(video.currentTime - video.duration) < 1,
        };
        //console.log("Updating watch history:", data);
        await api.post("/history", data);
      } catch (error) {
        // console.error("Error updating watch history:", error);
      }
    };

    const registerView = async () => {
      if (isRegistering || viewRegistered) return;

      try {
        setIsRegistering(true);
        //console.log("Registering view for video:", videoId);
        const response = await api.post(`/videos/${videoId}/view`);
        // console.log("View registered:", response.data);
        setViewRegistered(true);
        dispatch(updateVideoInteractions({ views: response.data.views }));
      } catch (error) {
        // console.error("Error registering view:", error);
        hasMetViewingCriteria.current = false;
      } finally {
        setIsRegistering(false);
      }
    };

    const handleTimeUpdate = () => {
      // Handle view counting
      if (!video.paused && !viewRegistered && !isRegistering) {
        const now = Date.now();
        const timeDiff = (now - lastUpdateRef.current) / 1000;
        watchTimeRef.current += timeDiff;
        lastUpdateRef.current = now;

        if (!hasMetViewingCriteria.current) {
          if (video.duration <= 30) {
            if (video.currentTime >= video.duration * 0.95) {
              hasMetViewingCriteria.current = true;
              registerView();
            }
          } else if (watchTimeRef.current >= 30) {
            hasMetViewingCriteria.current = true;
            registerView();
          }
        }
      }
    };

    const handlePlay = () => {
      setIsPlaying(true);
      lastUpdateRef.current = Date.now();
      progressInterval = setInterval(updateWatchHistory, 5000);
    };

    const handlePause = () => {
      setIsPlaying(false);
      clearInterval(progressInterval);
      updateWatchHistory();
    };

    const handleEnded = () => {
      setIsPlaying(false);
      clearInterval(progressInterval);
      if (!viewRegistered) {
        registerView();
      }
      updateWatchHistory().then(() => {
        video.currentTime = 0;
      });
    };

    video.addEventListener("timeupdate", handleTimeUpdate);
    video.addEventListener("play", handlePlay);
    video.addEventListener("pause", handlePause);
    video.addEventListener("ended", handleEnded);

    return () => {
      clearInterval(progressInterval);
      video.removeEventListener("timeupdate", handleTimeUpdate);
      video.removeEventListener("play", handlePlay);
      video.removeEventListener("pause", handlePause);
      video.removeEventListener("ended", handleEnded);
      updateWatchHistory();
    };
  }, [videoId, viewRegistered, isRegistering, dispatch]);

  // Event handlers
  const handleVideoLoaded = () => {
    const video = videoRef.current;
    if (!video) return;

    video.style.opacity = "0";
    setIsVideoReady(true);
    setIsLoading(false);
    setDuration(video.duration);

    if (savedPosition > 0) {
      //console.log("Setting video to position:", savedPosition);
      video.currentTime = savedPosition;
    }

    setTimeout(() => {
      video.style.opacity = "1";
    }, 100);
  };

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      videoContainerRef.current.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  const handleProgress = (e) => {
    const progressBar = e.currentTarget;
    const clickPosition =
      (e.pageX - progressBar.offsetLeft) / progressBar.offsetWidth;
    const newTime = clickPosition * duration;
    videoRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    videoRef.current.volume = newVolume;
  };

  return (
    <div
      ref={videoContainerRef}
      className="relative bg-black w-full rounded-lg overflow-hidden"
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
    >
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black">
          <div className="w-12 h-12 border-4 border-gray-600 border-t-gray-200 rounded-full animate-spin" />
        </div>
      )}

      <div className="relative aspect-video">
        <video
          ref={videoRef}
          className="w-full h-full cursor-pointer"
          style={{
            opacity: isVideoReady && savedPosition > 0 ? "0" : "1",
            transition: "opacity 0.3s ease-in",
          }}
          src={videoUrl}
          onLoadedData={handleVideoLoaded}
          onTimeUpdate={handleTimeUpdate}
          onClick={handlePlayPause}
        />

        <div
          className={`absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-4 transition-opacity duration-300 ${
            showControls ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className="w-full h-1 bg-gray-600 cursor-pointer mb-4"
            onClick={handleProgress}
          >
            <div
              className="h-full"
              style={{
                width: `${(currentTime / duration) * 100}%`,
                backgroundColor: "#9147ff",
              }}
            />
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <button
                onClick={handlePlayPause}
                className="text-white hover:bg-white/20 rounded-full p-2"
              >
                {isPlaying ? <BiPause size={24} /> : <BiPlay size={24} />}
              </button>

              <div className="flex items-center gap-2">
                <button className="text-white">
                  {volume > 0 ? (
                    <HiVolumeUp size={24} />
                  ) : (
                    <HiVolumeOff size={24} />
                  )}
                </button>
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.1"
                  value={volume}
                  onChange={handleVolumeChange}
                  className="w-20"
                />
              </div>

              <div className="text-white text-sm">
                {formatTime(currentTime)} / {formatTime(duration)}
              </div>
            </div>

            <div className="flex items-center gap-2">
              <button
                onClick={handleFullscreen}
                className="text-white hover:bg-white/20 rounded-full p-2"
              >
                {isFullscreen ? (
                  <BiExitFullscreen size={24} />
                ) : (
                  <BiFullscreen size={24} />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;
