import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { SignInButton, useAuth, useUser } from "@clerk/clerk-react";
import { useSearchParams } from "react-router-dom";
import api from "../../config/axios";
import { setSubscribedChannels } from "../../redux/slices/subscriptionSlice";
import VideoPlayer from "../../components/video/VideoPlayer";
import VideoInteractions from "../../components/video/VideoInteractions";
import Comments from "../../components/video/Comments";
import VideoPageSkeleton from "../../components/video/VideoPageSkeleton";
import SuggestedVideos from "../../components/video/SuggestedVideos";
import SubscribeButton from "../../components/shared/SubscribeButton";
import { Lock } from "lucide-react";

import {
  setCurrentVideo,
  setLoading,
  setError,
  setIsSubscribed,
} from "../../redux/slices/videoSlice";
import { setChannelInfo } from "../../redux/slices/channelSlice";
import {
  setStripeStatus,
  setStripeAccountId,
} from "../../redux/slices/stripeSlice";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import TipModal from "../../components/video/TipModal";
// import { BiLike, BiDislike, BiShare, BiDownload } from "react-icons/bi";

const formatViews = (views) => {
  if (views < 1000) return views;
  if (views < 1000000) return (views / 1000).toFixed(1) + "K";
  return (views / 1000000).toFixed(1) + "M";
};

const formatTimeAgo = (timestamp) => {
  const now = new Date();
  const createdAt = new Date(timestamp);
  const diff = now - createdAt;

  const seconds = Math.floor(diff / 1000);
  if (seconds < 60) return `${seconds}s ago`;
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${minutes}m ago`;
  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours}h ago`;
  const days = Math.floor(hours / 24);
  if (days < 30) return `${days}d ago`;
  const months = Math.floor(days / 30);
  if (months < 12) return `${months}mo ago`;
  const years = Math.floor(months / 12);
  return `${years}y ago`;
};

function VideoPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { currentVideo, loading, error } = useSelector((state) => state.videos);
  const channelInfo = useSelector((state) => state.channel.channelInfo);
  const { isSignedIn } = useAuth();
  const { user } = useUser();
  const [showSignInPrompt, setShowSignInPrompt] = useState(false);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showTipModal, setShowTipModal] = useState(false);
  const [watchProgress, setWatchProgress] = useState(null);
  const [searchParams] = useSearchParams();
  const playlistId = searchParams.get("list");
  const [playlist, setPlaylist] = useState(null);
  const [playlistVideos, setPlaylistVideos] = useState([]);

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#B94EEF",
      colorBackground: "#ffffff",
      colorText: "#424770",
      colorDanger: "#df1b41",
    },
  };

  const options = {
    mode: "payment",
    amount: 1000,
    currency: "usd",
    appearance,
  };

  // Handle modal state
  const handleOpenTipModal = () => {
    setShowTipModal(true);
  };

  const handleCloseTipModal = () => {
    setShowTipModal(false);
  };

  useEffect(() => {
    //console.log("VideoPage mounted with videoId:", id);

    const fetchVideoAndChannel = async () => {
      dispatch(setLoading(true));
      try {
        // Fetch video data
        const videoResponse = await api.get(`/videos/${id}`);
        dispatch(setCurrentVideo(videoResponse.data));

        // Fetch channel data
        const channelHandle = videoResponse.data.channelId.handle;
        const channelResponse = await api.get(`/channels/@${channelHandle}`);
        // console.log("Channel Response:", channelResponse);
        const channelData = channelResponse.data;

        // Update channel info
        dispatch(setChannelInfo(channelData));

        // Dispatch Stripe-related actions
        dispatch(
          setStripeStatus({
            tipsEnabled: channelData.tipsEnabled,
            monetizationEnabled: channelData.monetizationEnabled,
          })
        );
        dispatch(setStripeAccountId(channelData.stripeAccountId));

        // Check subscription status
        if (isSignedIn) {
          // Get current user's data
          const userData = await api.get("/users/me");

          dispatch(setSubscribedChannels(userData.data.subscribedChannels));
        } else {
          dispatch(setSubscribedChannels([]));
        }

        if (playlistId) {
          const playlistResponse = await api.get(
            `/users/playlists/${playlistId}`
          );
          setPlaylist(playlistResponse.data);
          setPlaylistVideos(playlistResponse.data.videos);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        dispatch(
          setError(err.response?.data?.message || "Failed to fetch video")
        );
      } finally {
        dispatch(setLoading(false));
      }
    };

    fetchVideoAndChannel();
  }, [id, dispatch, isSignedIn]);

  //console.log("User", user);

  // Use the custom hook to set the document title useDocumentTitle(currentVideo ? `Watching: ${currentVideo.title} - Your App` :
  /*   useDocumentTitle(
    currentVideo
      ? `${channelInfo.handle} : ${currentVideo.title} - Yivster`
      : "Loading..."
  );
 */
  const handleSubscribe = async () => {
    if (!isSignedIn) {
      setShowSignInPrompt(true);
      return;
    }

    try {
      if (!channelInfo?.handle) {
        console.error("No channel handle available");
        return;
      }

      const response = await api.post(
        `/channels/@${channelInfo.handle}/subscribe`
      );
      dispatch(
        setChannelInfo({
          ...channelInfo,
          subscriberCount: response.data.subscriberCount,
        })
      );
    } catch (err) {
      console.error("Error subscribing to channel:", err);
      if (err.response?.status === 400) {
        console.error(err.response.data.message);
      }
    }
  };

  const formatSubscriberCount = (count) => {
    if (count >= 1000000) return `${(count / 1000000).toFixed(1)}M`;
    if (count >= 1000) return `${(count / 1000).toFixed(1)}K`;
    return count;
  };

  const getPlaylistNavigation = () => {
    if (!playlistVideos.length) return { currentIndex: -1 };

    const currentIndex = playlistVideos.findIndex((video) => video._id === id);
    return {
      currentIndex,
      nextVideo:
        currentIndex < playlistVideos.length - 1
          ? playlistVideos[currentIndex + 1]
          : null,
      prevVideo: currentIndex > 0 ? playlistVideos[currentIndex - 1] : null,
    };
  };

  if (loading) return <VideoPageSkeleton />;
  if (error) return <div className="p-4 text-red-500">Error: {error}</div>;
  if (!currentVideo) return <div className="p-4">Video not found</div>;

  return (
    <div className="flex flex-col lg:flex-row gap-4 p-4">
      <div className="flex-grow lg:w-3/4">
        <VideoPlayer videoUrl={currentVideo.videoUrl} videoId={id} />
        {/* Add playlist navigation if in playlist */}
        {playlistId && (
          <div className="flex justify-between items-center mt-4 mb-2">
            <div className="text-sm text-gray-600">
              {playlist?.title} • Video{" "}
              {getPlaylistNavigation().currentIndex + 1} of{" "}
              {playlistVideos.length}
            </div>
            <div className="flex gap-2">
              {getPlaylistNavigation().prevVideo && (
                <Link
                  to={`/video/${
                    getPlaylistNavigation().prevVideo._id
                  }?list=${playlistId}`}
                  className="px-3 py-1 text-sm bg-gray-100 hover:bg-gray-200 rounded"
                >
                  Previous
                </Link>
              )}
              {getPlaylistNavigation().nextVideo && (
                <Link
                  to={`/video/${
                    getPlaylistNavigation().nextVideo._id
                  }?list=${playlistId}`}
                  className="px-3 py-1 text-sm bg-gray-100 hover:bg-gray-200 rounded"
                >
                  Next
                </Link>
              )}
            </div>
          </div>
        )}

        {currentVideo && channelInfo && (
          <div className="mt-4">
            {/* Title */}
            <Link to={`/channel/${channelInfo.handle}`}>
              <h1 className="text-xl font-bold mb-2">{currentVideo.title}</h1>
            </Link>

            {/* Main info container */}
            <div className="flex flex-col gap-4">
              {/* Top row: Channel info and actions */}
              <div className="flex justify-between items-center">
                {/* Left side: Channel info */}
                <div className="flex items-center gap-4">
                  <Link to={`/channel/${channelInfo.handle}`}>
                    {/*    <img
                      //src={channelInfo.avatar}
                      src={user?.imageUrl}
                      alt={channelInfo.name}
                      className="rounded-full w-10 h-10 object-cover"
                      onError={(e) => {
                        e.target.src = "https://placehold.co/40x40";
                      }}
                    /> */}
                    <img
                      src={channelInfo?.ownerId?.imageUrl} // Use the populated ownerId's imageUrl
                      alt={channelInfo.name}
                      className="rounded-full w-10 h-10 object-cover"
                      onError={(e) => {
                        e.target.src = "https://placehold.co/40x40";
                      }}
                    />
                  </Link>
                  <div>
                    <h3 className="font-medium text-base">
                      {channelInfo.name}
                    </h3>
                    <p className="text-sm text-gray-600">
                      {formatSubscriberCount(channelInfo.subscriberCount)}{" "}
                      subscribers
                    </p>
                  </div>

                  <SubscribeButton
                    channelId={channelInfo._id} // Use channelInfo instead of channel
                    channelHandle={channelInfo.handle}
                    onSubscriptionChange={(result) => {
                      if (result === "signin-required") {
                        setShowSignInPrompt(true);
                      } else {
                        dispatch(
                          setChannelInfo({
                            ...channelInfo,
                            subscriberCount: result.subscriberCount,
                          })
                        );
                      }
                    }}
                  />
                </div>
                {/* Right side: Video Interactions */}
                <VideoInteractions
                  video={currentVideo}
                  channelInfo={channelInfo}
                  onTipClick={handleOpenTipModal}
                />
              </div>

              {/* Description */}
              <div className="bg-gray-100 rounded-xl p-3">
                <div className="flex gap-2 text-sm text-black-700 mb-2">
                  <span>{formatViews(currentVideo.views)} views</span>
                  <span>•</span>
                  <span>{formatTimeAgo(currentVideo.createdAt)}</span>
                </div>
                <div
                  className={`text-sm whitespace-pre-wrap ${
                    !isExpanded ? "max-h-[80px] overflow-hidden" : ""
                  }`}
                >
                  {currentVideo.description}
                </div>
                <button
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="text-sm font-medium mt-2"
                >
                  {isExpanded ? "Show less" : "Show more"}
                </button>
              </div>
            </div>
          </div>
        )}

        <Comments videoId={id} userImage={user?.imageUrl} />
      </div>

      {/* Sidebar section */}
      <div className="lg:w-[400px]">
        {playlistId ? (
          <>
            <div className="bg-white rounded-lg mb-4 border border-gray-200">
              {/* Rest of the playlist content stays exactly the same */}
              <div className="p-3 flex items-center justify-between border-b border-gray-200">
                <div>
                  <h3 className="text-lg font-bold mb-1">{playlist?.title}</h3>
                  <div className="flex items-center text-xs text-gray-600">
                    <Lock className="w-3 h-3 mr-1" /> {/* Add Lock icon */}
                    <span>{channelInfo?.name}</span>
                    <span className="mx-1">•</span>
                    <span>
                      {getPlaylistNavigation().currentIndex + 1}/
                      {playlistVideos.length}
                    </span>
                  </div>
                </div>
                <button className="p-2 hover:bg-gray-100 rounded-full">
                  <svg className="w-5 h-5" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                    />
                  </svg>
                </button>
              </div>

              {/* Playlist controls */}
              <div className="px-3 pb-2 flex gap-2">
                {/*     <button className="hover:bg-gray-100 p-1 rounded">
                  <svg className="w-5 h-5" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M17 15l-5-5-5 5z" />
                  </svg>
                </button>
                <button className="hover:bg-gray-100 p-1 rounded">
                  <svg className="w-5 h-5" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M7 7h10v10H7z" />
                  </svg>
                </button> */}
              </div>

              {/* Playlist videos - keeping the same compact list style */}
              <div className="overflow-y-auto max-h-[400px]">
                {playlistVideos.map((video, index) => (
                  <Link
                    key={video._id}
                    to={`/video/${video._id}?list=${playlistId}`}
                    className={`flex p-2 hover:bg-gray-100 ${
                      video._id === id ? "bg-gray-100" : ""
                    }`}
                  >
                    <div className="w-8 text-xs text-gray-600 pt-2 text-center">
                      {video._id === id ? (
                        <span className="text-[#B94EEF]">▶</span>
                      ) : (
                        index + 1
                      )}
                    </div>

                    <div className="relative w-24 h-14">
                      <img
                        src={video.thumbnailUrl}
                        alt=""
                        className="w-full h-full object-cover rounded"
                      />
                      <span className="absolute bottom-1 right-1 bg-black/80 text-white text-xs px-1 rounded">
                        {video.duration}
                      </span>
                    </div>

                    <div className="ml-2 flex-1 min-w-0">
                      <h4 className="text-sm font-medium line-clamp-2">
                        {video.title}
                      </h4>
                      <p className="text-xs text-gray-600 mt-1">
                        {video.channelId?.name}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
            </div>

            {/* Suggested videos below playlist */}
            <SuggestedVideos currentVideoId={id} />
          </>
        ) : (
          <SuggestedVideos currentVideoId={id} />
        )}
      </div>

      {/* Stripe Elements wrapper */}
      {showTipModal && (
        <Elements stripe={stripePromise} options={options}>
          <TipModal
            isOpen={true}
            onClose={handleCloseTipModal}
            channelInfo={channelInfo}
            currentVideo={currentVideo}
          />
        </Elements>
      )}

      {showSignInPrompt && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-sm w-full mx-4">
            <h3 className="text-lg font-bold mb-2">Sign in to subscribe</h3>
            <p className="text-gray-600 mb-4">
              Sign in to subscribe to this channel and get notified about new
              videos!
            </p>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowSignInPrompt(false)}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
              >
                Cancel
              </button>
              <SignInButton mode="modal">
                <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                  Sign In
                </button>
              </SignInButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoPage;
