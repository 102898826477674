import React from "react";
import { SignInButton } from "@clerk/clerk-react";

function SignInPrompt({ isOpen, onClose, action }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg max-w-sm w-full mx-4">
        <h3 className="text-lg font-bold mb-2">Sign in to {action}</h3>
        <p className="text-gray-600 mb-4">
          Join the conversation! Sign in to interact with comments.
        </p>
        <div className="flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
          >
            Cancel
          </button>
          <SignInButton mode="modal">
            <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
              Sign In
            </button>
          </SignInButton>
        </div>
      </div>
    </div>
  );
}

export default SignInPrompt;
