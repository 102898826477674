import React from "react";

const CommunityGuidelines = () => {
  return (
    <div className="min-h-screen text-black p-8 max-w-6xl mx-auto">
      {/* Header */}
      <div className="mb-12">
        <h1 className="text-[#4cc9f0] text-5xl font-bold mb-4">
          Community Guidelines
        </h1>
        <div className="text-black-400 mb-6">
          Date Modified: <time dateTime="2024-08-26">[August 26, 2024]</time>
        </div>
      </div>

      {/* Introduction */}
      <section className="mb-12">
        <h2 className="text-[#4cc9f0] text-3xl font-bold mb-6">
          Introduction to Safety on Yivster
        </h2>
        <p className="text-black-300 leading-relaxed">
          Welcome to Yivster! Our mission is to create a welcoming and inclusive
          community where everyone can express themselves creatively and connect
          with others. To maintain a safe environment, we ask that all users
          follow these community guidelines. Violations may result in content
          removal, suspension, or termination of your account.
        </p>
      </section>

      {/* Hate Speech Section */}
      <section className="mb-12">
        <h2 className="text-[#4cc9f0] text-3xl font-bold mb-6">
          1. Hate Speech and Harassment
        </h2>
        <p className="text-black-300 leading-relaxed">
          Yivster does not allow content that promotes hatred or violence
          against individuals or groups based on race, ethnicity, religion,
          gender, sexual orientation, disability, or any other characteristic
          associated with systemic discrimination or marginalization. We do not
          tolerate any form of harassment, bullying, or abusive behavior. This
          includes but is not limited to personal attacks, unwanted sexual
          advances, and persistent negative comments.
        </p>
      </section>

      {/* Protection of Minors Section with List */}
      <section className="mb-12">
        <h2 className="text-[#4cc9f0] text-3xl font-bold mb-6">
          3. Protection of Minors
        </h2>
        <p className="text-black-300 leading-relaxed mb-4">
          Yivster is committed to providing a safe environment for all users,
          especially minors. We enforce strict policies to protect children from
          exploitation and harm.
        </p>
        <ul className="space-y-4">
          <li className="text-black-300">
            <span className="text-[#4cc9f0] font-semibold">
              Age Requirement:{" "}
            </span>
            Users must be at least 13 years old or meet the minimum age required
            to provide digital consent in their specific State, Territory, or
            Region.
          </li>
          <li className="text-black-300">
            <span className="text-[#4cc9f0] font-semibold">
              Parental Involvement:{" "}
            </span>
            Minors using Yivster must do so with the active involvement of a
            parent or legal guardian. The parent or guardian must consent to
            Yivster's Terms of Service and Community Guidelines.
          </li>
          <li className="text-black-300">
            <span className="text-[#4cc9f0] font-semibold">
              Content Restrictions Involving Minors:{" "}
            </span>
            Adult creators are prohibited from streaming or uploading content
            featuring users under 18 unless the minor is in the immediate
            presence of a parent or legal guardian. Users must respect the
            privacy and safety of minors at all times.
          </li>

          <li className="text-black-300">
            <span className="text-[#4cc9f0] font-semibold">
              Zero-Tolerance Policy:{" "}
            </span>
            Yivster strictly prohibits the creation, distribution, or promotion
            of Child Sexual Abuse Material (CSAM). Any violation of this policy
            will result in immediate action, including reporting to law
            enforcement.
          </li>
        </ul>
      </section>

      {/* Nudity and Sexual Content Section */}
      <section className="mb-12">
        <h2 className="text-[#4cc9f0] text-3xl font-bold mb-6">
          4. Nudity and Sexual Content
        </h2>
        <p className="text-black-300 leading-relaxed">
          Yivster does not allow explicit sexual content, nudity, or any content
          that is sexually suggestive. This includes but is not limited to
          pornography, sexual acts, and sexually explicit language. Content
          intended for sexual gratification is strictly prohibited.
        </p>
      </section>

      {/* Sensitive Information and Doxxing Section */}
      <section className="mb-12">
        <h2 className="text-[#4cc9f0] text-3xl font-bold mb-6">
          5. Sensitive Information and Doxxing
        </h2>
        <p className="text-black-300 leading-relaxed">
          Sharing or threatening to share another person's private information
          without their consent is strictly prohibited. This includes but is not
          limited to addresses, phone numbers, email addresses, and financial
          information. Doxxing, or the act of publicly revealing private
          information about an individual, is not tolerated.
        </p>
      </section>

      {/* Copyright and Intellectual Property Section */}
      <section className="mb-12">
        <h2 className="text-[#4cc9f0] text-3xl font-bold mb-6">
          6. Copyright and Intellectual Property
        </h2>
        <p className="text-black-300 leading-relaxed">
          Respect the intellectual property rights of others. Do not share,
          distribute, or use copyrighted material without proper authorization
          or a valid license. This includes but is not limited to music, videos,
          images, and written content.
        </p>
      </section>

      {/* Misinformation and Harmful Content Section */}
      <section className="mb-12">
        <h2 className="text-[#4cc9f0] text-3xl font-bold mb-6">
          7. Misinformation and Harmful Content
        </h2>
        <p className="text-black-300 leading-relaxed">
          Yivster does not allow the spread of misinformation or content that
          could cause harm to individuals or the community. This includes but is
          not limited to false information about health, safety, and elections.
          Content that promotes harmful behaviors or challenges is also
          prohibited.
        </p>
      </section>

      {/* Spam, Scams, and Deceptive Practices Section */}
      <section className="mb-12">
        <h2 className="text-[#4cc9f0] text-3xl font-bold mb-6">
          8. Spam, Scams, and Deceptive Practices
        </h2>
        <p className="text-black-300 leading-relaxed">
          Do not engage in spamming, scamming, or any deceptive practices. This
          includes but is not limited to sending unsolicited messages, creating
          fake accounts, and attempting to deceive users for financial gain or
          other benefits.
        </p>
      </section>

      {/* Illegal Activities Section */}
      <section className="mb-12">
        <h2 className="text-[#4cc9f0] text-3xl font-bold mb-6">
          9. Illegal Activities
        </h2>
        <p className="text-black-300 leading-relaxed">
          Yivster does not allow content that promotes or engages in illegal
          activities. This includes but is not limited to drug use, human
          trafficking, and the sale of illegal goods or services. Any content
          that violates local, national, or international laws is strictly
          prohibited.
        </p>
      </section>

      {/* Community Moderation and Reporting Section */}
      <section className="mb-12">
        <h2 className="text-[#4cc9f0] text-3xl font-bold mb-6">
          10. Community Moderation and Reporting
        </h2>
        <p className="text-black-300 leading-relaxed">
          Yivster relies on community moderation to maintain a safe and
          respectful environment. Users are encouraged to report any content or
          behavior that violates these guidelines. Our moderation team will
          review reports and take appropriate action.
        </p>
      </section>

      {/* Enforcement and Consequences Section */}
      <section className="mb-12">
        <h2 className="text-[#4cc9f0] text-3xl font-bold mb-6">
          11. Enforcement and Consequences
        </h2>
        <p className="text-black-300 leading-relaxed">
          Violations of these guidelines may result in content removal,
          suspension, or termination of your account. In severe cases, we may
          report violations to law enforcement. Yivster reserves the right to
          take any action necessary to protect the community and enforce these
          guidelines.
        </p>
      </section>

      {/* Final Section */}
      <section className="mb-12">
        <h2 className="text-[#4cc9f0] text-3xl font-bold mb-6">
          Thank you for being a part of Yivster!
        </h2>
        <p className="text-black-300 leading-relaxed">
          Together, we can build a community that is safe, respectful, and
          enjoyable for everyone. If you have any questions or need assistance,
          please contact our support team.
        </p>
      </section>

      {/* Footer */}
      <div className="mt-12 pt-8 border-t border-gray-800">
        <p className="text-gray-400 text-sm">
          © {new Date().getFullYear()} Yivster. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default CommunityGuidelines;
