import React from "react";
import { Link } from "react-router-dom";
import {
  formatDuration,
  formatViewCount,
  timeAgo,
} from "../../utils/formatters";

function ChannelVideoCard({ video, watchProgress }) {
  const defaultThumbnail = "https://placehold.co/480x270";

  const handleImageError = (e) => {
    e.target.src = defaultThumbnail;
  };

  return (
    <Link to={`/video/${video._id}`} className="block group">
      <div className="flex flex-col">
        {/* Thumbnail Container */}
        <div className="relative mb-2">
          <img
            src={video.thumbnailUrl || defaultThumbnail}
            alt={video.title}
            className="w-full aspect-video object-cover rounded-xl group-hover:rounded-none transition-all duration-200"
            onError={handleImageError}
          />
          {/* Duration Badge - updated styling */}
          {video.duration && (
            <span className="absolute bottom-1 right-1 bg-black/80 text-white text-xs px-1 py-px rounded leading-tight">
              {formatDuration(video.duration)}
            </span>
          )}

          {/* Watch Progress Bar */}
          {watchProgress && watchProgress.lastPosition > 0 && (
            <div className="absolute bottom-0 left-0 w-full h-1 bg-gray-600/60">
              <div
                className="h-full"
                style={{
                  width: `${
                    (watchProgress.lastPosition / video.duration) * 100
                  }%`,
                  backgroundColor: "#9147ff",
                }}
              />
            </div>
          )}
        </div>

        {/* Video Info */}
        <div className="flex flex-col">
          {/* Title - updated font and line height */}
          <h3 className="text-[14px] font-medium leading-[20px] line-clamp-2 mb-1">
            {video.title}
          </h3>

          {/* Stats Row - updated spacing and font size */}
          <div className="flex flex-col text-[12px] text-gray-600">
            <span>{formatViewCount(video.views)} views</span>
            <span>{timeAgo(video.createdAt)}</span>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ChannelVideoCard;
