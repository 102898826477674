import React from "react";

const PrivacyPage = () => {
  return (
    <div className="min-h-screen p-8 max-w-6xl mx-auto">
      {/* Header */}
      <div className="mb-12">
        <h1 className="text-[#4cc9f0] text-4xl font-bold mb-4">
          Privacy Policy
        </h1>
        <div className="text-black mb-6">
          Date Modified: <time dateTime="2024-08-26">[August 26, 2024]</time>
        </div>
        <p className="text-black leading-relaxed">
          Welcome to Yivster! Your privacy is critically important to us. This
          Privacy Policy explains how we collect, use, disclose, and safeguard
          your information when you use our platform, including our website,
          mobile apps, and other related services (collectively, the
          "Services"). By using Yivster, you agree to the collection and use of
          information in accordance with this policy.
        </p>
      </div>

      {/* Information We Collect Section */}
      <section className="mb-12">
        <h2 className="text-[#4cc9f0] text-3xl font-bold mb-6">
          1. Information We Collect
        </h2>

        <div className="mb-8">
          <h3 className="text-[#4cc9f0] text-2xl font-bold mb-4">
            1.1 Information You Provide to Us:
          </h3>
          <ul className="space-y-4">
            <li className="text-black">
              <span className="text-[#4cc9f0] font-semibold">
                Account Information:
              </span>{" "}
              When you create an account, we collect information such as your
              username, password, email address, and other details necessary for
              account creation.
            </li>
            <li className="text-black">
              <span className="text-[#4cc9f0] font-semibold">
                Profile Information:
              </span>{" "}
              You may choose to provide additional information, such as a
              profile picture, bio, or links to social media accounts.
            </li>
            <li className="text-black">
              <span className="text-[#4cc9f0] font-semibold">
                Content You Create:
              </span>{" "}
              We collect the content you upload, stream, or post on Yivster,
              including videos, comments, messages, and other media.
            </li>
            <li className="text-black">
              <span className="text-[#4cc9f0] font-semibold">
                Payment Information:
              </span>{" "}
              If you make purchases or receive payments through Yivster, we use
              Stripe to process your payments We collect only the necessary
              information to facilitate these transactions Stripe may collect
              and process your payment information directly, and their use of
              your data is governed by their own privacy policy
            </li>
          </ul>
        </div>

        {/* Continue with other sections following the same pattern... */}

        <div className="mb-8">
          <h3 className="text-[#4cc9f0] text-2xl font-bold mb-4">
            1.2 Information We Collect Automatically
          </h3>
          <ul className="space-y-4">
            <li className="listItem">
              <span className="text-[#4cc9f0] font-semibold">Usage Data:</span>{" "}
              We collect information about how you use Yivster, including the
              videos you watch, the content you interact with, the ads you view,
              and the time you spend on different parts of our Services
            </li>
            <li className="listItem">
              <span className="text-[#4cc9f0] font-semibold">
                Device and Connection Information:
              </span>{" "}
              We collect information about the device you use to access Yivster,
              such as your IP address, browser type, operating system, and
              mobile network information
            </li>
            <li className="listItem">
              <span className="text-[#4cc9f0] font-semibold">
                Cookies and Similar Technologies:
              </span>{" "}
              We use cookies and other tracking technologies to collect
              information about your activity on Yivster, including your
              preferences and usage patterns
            </li>
          </ul>
        </div>

        <div className="mb-8">
          <h3 className="text-[#4cc9f0] text-2xl font-bold mb-4">
            1.3 Information We Collect from Third Parties
          </h3>
          <ul className="space-y-4">
            <li className="listItem">
              <span className="text-[#4cc9f0] font-semibold">
                Social Media and Linked Services:
              </span>{" "}
              If you link your Yivster account to third-party services (like
              social media platforms), we may collect information from those
              services, including your profile details and any content you've
              shared with those platforms.
            </li>
            <li className="listItem">
              <span className="text-[#4cc9f0] font-semibold">
                Advertising Partners:
              </span>{" "}
              We may receive information from our advertising partners to help
              us provide you with relevant ads and to track the effectiveness of
              our advertising campaigns.
            </li>
          </ul>
        </div>

        <h2 className="text-[#4cc9f0] text-2xl font-bold mb-4">
          2. How We Use Your Information
        </h2>
        <ul className="space-y-4">
          <li className="listItem">
            <span className="text-[#4cc9f0] font-semibold">
              To Provide and Improve Our Services:
            </span>{" "}
            We use your information to operate, maintain, and improve Yivster,
            including personalizing content, analyzing usage, and ensuring the
            security of our platform.
          </li>
          <li className="listItem">
            <span className="text-[#4cc9f0] font-semibold">
              To Communicate with You:
            </span>{" "}
            We use your contact information to send you updates, newsletters,
            and other communications related to your use of Yivster. You can opt
            out of these communications at any time.
          </li>
          <li className="listItem">
            <span className="text-[#4cc9f0] font-semibold">
              To Process Payments:
            </span>{" "}
            We use Stripe to process payments on Yivster. Your payment
            information is handled securely, and we only collect the necessary
            details to facilitate transactions. For more information on how
            Stripe handles your data, please refer to the Stripe Privacy Policy.
          </li>
          <li className="listItem">
            <span className="text-[#4cc9f0] font-semibold">
              To Serve Personalized Ads:
            </span>{" "}
            We use the information we collect to deliver personalized
            advertising that we believe may be of interest to you. You can
            manage your ad preferences through your account settings.
          </li>
          <li className="listItem">
            <span className="text-[#4cc9f0] font-semibold">
              To Enforce Our Policies:
            </span>{" "}
            We may use your information to enforce our Community Guidelines,
            Terms of Service, and other legal agreements, including
            investigating potential violations.
          </li>
        </ul>

        <h2 className="text-[#4cc9f0] text-2xl font-bold mb-4">
          3. How We Share Your Information
        </h2>
        <ul className="space-y-4">
          <li className="listItem">
            <span className="text-[#4cc9f0] font-semibold">
              With Other Users:
            </span>{" "}
            Depending on your account settings, the content you upload or post
            on Yivster may be visible to other users. This includes your
            username, profile information, and any public interactions you have
            on the platform.
          </li>
          <li className="listItem">
            <span className="text-[#4cc9f0] font-semibold">
              With Service Providers:
            </span>{" "}
            We share your information with third-party service providers who
            assist us with payment processing (including Stripe), data analysis,
            marketing, and other services. These providers are contractually
            obligated to protect your information and use it only for the
            services they provide.
          </li>
          <li className="listItem">
            <span className="text-[#4cc9f0] font-semibold">
              With Advertising Partners:
            </span>{" "}
            We may share information with advertising partners to deliver
            personalized ads and to track the performance of our ad campaigns.
          </li>
          <li className="listItem">
            <span className="text-[#4cc9f0] font-semibold">
              For Legal Reasons:
            </span>{" "}
            We may disclose your information if required by law, or in response
            to legal processes, to protect our rights, or to prevent harm to
            users or the public.
          </li>
          <li className="listItem">
            <span className="text-[#4cc9f0] font-semibold">
              In Business Transfers:
            </span>{" "}
            In the event of a merger, acquisition, or sale of assets, your
            information may be transferred as part of that transaction. We will
            notify you of any such change in ownership or transfer of your
            personal data.
          </li>
        </ul>

        <h2 className="text-[#4cc9f0] text-2xl font-bold mb-4">
          4. Your Choices and Controls
        </h2>
        <ul className="space-y-4">
          <li className="listItem">
            <span className="text-[#4cc9f0] font-semibold">
              Account Settings:
            </span>{" "}
            You can update your account information and preferences through your
            Yivster account settings, including managing your privacy settings
            and communication preferences.
          </li>
          <li className="listItem">
            <span className="text-[#4cc9f0] font-semibold">
              Data Access and Portability:
            </span>{" "}
            You have the right to request access to the information we hold
            about you and to receive a copy of that information in a portable
            format.
          </li>
          <li className="listItem">
            <span className="text-[#4cc9f0] font-semibold">Data Deletion:</span>{" "}
            You can request the deletion of your account and personal
            information by contacting our support team. Please note that certain
            data may be retained for legal or security reasons.
          </li>
          <li className="listItem">
            <span className="text-[#4cc9f0] font-semibold">
              Cookie Preferences:
            </span>{" "}
            You can manage your cookie preferences through your browser settings
            or Yivster’s cookie management tools.
          </li>
        </ul>

        <h2 className="text-[#4cc9f0] text-2xl font-bold mb-4">
          5. Data Security
        </h2>
        <p className="text-black">
          We take the security of your information seriously and implement
          appropriate technical and organizational measures to protect it.
          However, no method of transmission over the Internet or electronic
          storage is completely secure, and we cannot guarantee the absolute
          security of your data.
        </p>

        <h2 className="text-[#4cc9f0] text-2xl font-bold mb-4">
          6. Children's Privacy
        </h2>
        <p className="text-black">
          Yivster is not intended for use by individuals under the age of 13, or
          the minimum age required to provide digital consent in your
          jurisdiction. We do not knowingly collect personal information from
          children under this age. If we become aware that we have collected
          personal information from a child under the relevant age, we will take
          steps to delete such information promptly.
        </p>

        <h2 className="text-[#4cc9f0] text-2xl font-bold mb-4">
          7. Changes to this Privacy Policy
        </h2>
        <p className="text-black">
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. We will notify you of any
          significant changes by posting the new policy on Yivster and, where
          appropriate, by other means. Your continued use of the Services after
          any such changes signifies your acceptance of the updated Privacy
          Policy.
        </p>

        <h2 className="text-[#4cc9f0] text-2xl font-bold mb-4">
          8. Contact Us
        </h2>
        <p className="text-black">
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at{" "}
          <a href="mailto:corporate@yivster.com" className="link">
            corporate@yivster.com
          </a>
          .
        </p>
      </section>

      {/* Important Notices */}
      <div className="">
        <p className="text-[#4cc9f0] font-bold mb-2">
          Important Notice About Your Privacy:
        </p>
        <p className="text-black">
          We take the security of your information seriously and implement
          appropriate technical and organizational measures to protect it.
        </p>
      </div>

      {/* Contact Section */}
      <section className="mt-12">
        <h2 className="text-[#4cc9f0] text-3xl font-bold mb-6">
          8. Contact Us
        </h2>
        <p className="text-black">
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at{" "}
          <a
            href="mailto:corporate@yivster.com"
            className="text-[#4cc9f0] hover:underline"
          >
            corporate@yivster.com
          </a>
        </p>
      </section>

      {/* Footer */}
      <div className="mt-12 pt-8 border-t border-gray-800">
        <p className="text-gray-400 text-sm">
          © {new Date().getFullYear()} Yivster. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPage;
