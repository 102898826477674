// components/video/CommentItem.jsx
import React, { useState } from "react";
import { formatDistance } from "date-fns";
import { BiLike, BiDislike } from "react-icons/bi";
import { BsThreeDots } from "react-icons/bs";
import AddComment from "./AddComment";
import SignInPrompt from "../shared/SignInPrompt";
import CommentReportModal from "./CommentReportModal";
import { useAuth } from "@clerk/clerk-react";
import { useUser } from "@clerk/clerk-react";
import { useToast } from "../shared/Toast";

function CommentItem({ comment, onReply, onLike, onDislike, isReply = false }) {
  const { user } = useUser();
  const { isSignedIn } = useAuth();
  const [isReplying, setIsReplying] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [showSignInPrompt, setShowSignInPrompt] = useState(false);
  const [signInAction, setSignInAction] = useState(null);
  const [likes, setLikes] = useState(comment.likes || []);
  const [dislikes, setDislikes] = useState(comment.dislikes || []);
  const [hasLiked, setHasLiked] = useState(false);
  const [hasDisliked, setHasDisliked] = useState(false);

  const [showMenu, setShowMenu] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const { showToast, ToastComponent } = useToast();

  //const [getDisplayImage, setDisplayImage] = useState(null);

  /*   const handleLike = () => {
    if (hasLiked) {
      setLikes(likes.filter((id) => id !== comment._id));
      setHasLiked(false);
    } else {
      setLikes([...likes, comment._id]);
      setHasLiked(true);
      if (hasDisliked) {
        setDislikes(dislikes.filter((id) => id !== comment._id));
        setHasDisliked(false);
      }
    }
    onLike(comment._id);
  }; */

  const handleLike = () => {
    if (!isSignedIn) {
      setSignInAction("like");
      setShowSignInPrompt(true);
      return;
    }

    if (hasLiked) {
      setLikes(likes.filter((id) => id !== comment._id));
      setHasLiked(false);
    } else {
      setLikes([...likes, comment._id]);
      setHasLiked(true);
      if (hasDisliked) {
        setDislikes(dislikes.filter((id) => id !== comment._id));
        setHasDisliked(false);
      }
    }
    onLike(comment._id);
  };

  const handleDislike = () => {
    if (!isSignedIn) {
      setSignInAction("dislike");
      setShowSignInPrompt(true);
      return;
    }

    if (hasDisliked) {
      setDislikes(dislikes.filter((id) => id !== comment._id));
      setHasDisliked(false);
    } else {
      setDislikes([...dislikes, comment._id]);
      setHasDisliked(true);
      if (hasLiked) {
        setLikes(likes.filter((id) => id !== comment._id));
        setHasLiked(false);
      }
    }
    onDislike(comment._id);
  };

  const handleReplyClick = () => {
    setIsReplying(!isReplying);
  };

  const handleReport = () => {
    if (!isSignedIn) {
      setSignInAction("report");
      setShowSignInPrompt(true);
      return;
    }
    setShowReportModal(true);
    setShowMenu(false);
  };

  //console.log(comment.displayAvatar);

  return (
    <div className={`flex gap-4 ${isReply ? "ml-12" : ""}`}>
      <img
        src={comment.userId?.imageUrl}
        alt={comment.displayName}
        className="w-10 h-10 rounded-full"
        onError={(e) => {
          e.target.src =
            "https://img.clerk.com/eyJ0eXBlIjoiZGVmYXVsdCIsImlpZCI6Imluc18ycEpJRm1YSTJsTUlJcU9BZGVoTzFyNndmWUYiLCJyaWQiOiJ1c2VyXzJwamd0Y0oyR1lqZDVldUt3d0tNb01XMHRkZyJ9";
        }}
      />
      <div className="flex-1">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="font-medium text-[13px]">
              {comment.displayName}
            </span>
            <span className="text-[13px] text-gray-600">
              {formatDistance(new Date(comment.createdAt), new Date(), {
                addSuffix: true,
              })}
            </span>
          </div>
          {/* Menu Button */}
          <div className="relative">
            <button
              onClick={() => setShowMenu(!showMenu)}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <BsThreeDots className="w-4 h-4 text-gray-600" />
            </button>
            {showMenu && (
              <div className="absolute right-0 mt-1 w-48 bg-white rounded-lg shadow-lg border py-1 z-50">
                <button
                  onClick={handleReport}
                  className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100"
                >
                  Report
                </button>
              </div>
            )}
          </div>
        </div>

        <p className="mt-1 text-[14px]">
          {comment.replyToUser && (
            <span className="text-blue-600 mr-1">@{comment.replyToUser} </span>
          )}
          {comment.content}
        </p>

        <div className="flex items-center gap-2 mt-2">
          <button
            onClick={handleLike}
            className={`flex items-center p-2 hover:bg-gray-100 rounded-full ${
              hasLiked ? "text-blue-600" : "text-gray-600"
            }`}
          >
            <BiLike size={16} />
            <span className="ml-1 text-xs">{likes.length}</span>
          </button>
          <button
            onClick={handleDislike}
            className={`flex items-center p-2 hover:bg-gray-100 rounded-full ${
              hasDisliked ? "text-blue-600" : "text-gray-600"
            }`}
          >
            <BiDislike size={16} />
            <span className="ml-1 text-xs">{dislikes.length}</span>
          </button>
          <button
            onClick={handleReplyClick}
            className="px-2 py-1.5 hover:bg-gray-100 rounded-full text-[13px] font-medium"
          >
            Reply
          </button>
        </div>

        {comment.replies?.length > 0 && !isReply && (
          <div className="mt-2">
            <button
              onClick={() => setShowReplies(!showReplies)}
              className="text-[#065fd4] text-[14px] font-medium hover:text-[#065fd4]/90 flex items-center gap-2"
            >
              <span className={`w-4 h-4 ${showReplies ? "rotate-180" : ""}`}>
                ▼
              </span>
              {showReplies ? "Hide" : `Show ${comment.replies.length}`} replies
            </button>
            {showReplies && (
              <div className="mt-4">
                {comment.replies.map((reply) => (
                  <CommentItem
                    key={reply._id}
                    comment={reply}
                    onReply={onReply}
                    onLike={onLike}
                    onDislike={onDislike}
                    isReply={true}
                  />
                ))}
              </div>
            )}
          </div>
        )}

        {isReplying && (
          <div className="mt-4">
            <AddComment
              onSubmit={(content) => {
                onReply(comment._id, content);
                setIsReplying(false);
              }}
              userImage={user?.imageUrl}
            />
          </div>
        )}
      </div>

      <SignInPrompt
        isOpen={showSignInPrompt}
        onClose={() => setShowSignInPrompt(false)}
        action={signInAction}
      />

      {showReportModal && (
        <CommentReportModal
          isOpen={showReportModal}
          onClose={() => setShowReportModal(false)}
          comment={comment}
        />
      )}
    </div>
  );
}

export default CommentItem;
