// Comments.jsx
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineUser } from "react-icons/ai";
import { useAuth, useUser } from "@clerk/clerk-react";
import api from "../../config/axios";
import {
  setComments,
  addComment, // Add this
  addReply, // Add this
  updateCommentInteraction,
} from "../../redux/slices/commentSlice";
import CommentItem from "./CommentItem";
import AddComment from "./AddComment";

function Comments({ videoId }) {
  const dispatch = useDispatch();
  const { comments } = useSelector((state) => state.comments);
  const [userDisplayImage, setUserDisplayImage] = useState(null);
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch user/channel info
  /*   useEffect(() => {
    const getUserInfo = async () => {
      if (user) {
        try {
          const userResponse = await api.get("/users/me");
          if (
            userResponse.data.hasChannel &&
            userResponse.data.defaultChannelId
          ) {
            // Use the avatar directly from the channel object
            setUserDisplayImage(user?.imageUrl);
          } else {
            setUserDisplayImage(<AiOutlineUser />);
          }
        } catch (error) {
          console.error("Error fetching user info:", error);
          setUserDisplayImage(user.imageUrl);
        }
      } else {
        setUserDisplayImage(<AiOutlineUser />);
      }
    };

    if (user) {
      getUserInfo();
    }
  }, [user]); */

  useEffect(() => {
    const getUserInfo = async () => {
      if (user) {
        try {
          const userResponse = await api.get("/users/me");
          // Always use the user's imageUrl directly
          setUserDisplayImage(user.imageUrl);
        } catch (error) {
          console.error("Error fetching user info:", error);
          setUserDisplayImage(user.imageUrl);
        }
      } else {
        // Use Clerk's default avatar instead of AiOutlineUser component
        setUserDisplayImage("https://img.clerk.com/default-avatar.png");
      }
    };

    if (user) {
      getUserInfo();
    }
  }, [user]);

  // Fetch comments
  useEffect(() => {
    const fetchComments = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/comments/video/${videoId}`);
        dispatch(setComments(response.data));
      } catch (err) {
        setError(err.response?.data?.message || "Failed to fetch comments");
      } finally {
        setLoading(false);
      }
    };

    if (videoId) {
      fetchComments();
    }
  }, [videoId, dispatch]);

  const handleAddComment = async (content) => {
    try {
      console.log("Current user image:", user?.imageUrl); // Debug line

      const response = await api.post("/comments", {
        content,
        videoId,
      });
      console.log("New comment data:", response.data); // Debug line

      dispatch(addComment(response.data));
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleLike = async (commentId) => {
    try {
      const response = await api.post(`/comments/${commentId}/like`);
      dispatch(
        updateCommentInteraction({
          commentId,
          likes: response.data.likes,
          dislikes: response.data.dislikes,
        })
      );
    } catch (error) {
      console.error("Error liking comment:", error);
    }
  };

  const handleDislike = async (commentId) => {
    try {
      const response = await api.post(`/comments/${commentId}/dislike`);
      dispatch(
        updateCommentInteraction({
          commentId,
          likes: response.data.likes,
          dislikes: response.data.dislikes,
        })
      );
    } catch (error) {
      console.error("Error disliking comment:", error);
    }
  };

  const handleAddReply = async (commentId, content) => {
    try {
      // Find the comment in either top-level comments or their replies
      const findComment = (commentId) => {
        // First check top-level comments
        let comment = comments.find((c) => c._id === commentId);
        if (comment) return comment;

        // If not found, check replies
        for (let topComment of comments) {
          if (topComment.replies) {
            const reply = topComment.replies.find((r) => r._id === commentId);
            if (reply) return reply;
          }
        }
        return null;
      };

      const commentToReplyTo = findComment(commentId);
      if (!commentToReplyTo) {
        throw new Error("Comment not found");
      }

      // Find the parent comment if this is a reply
      const parentComment = commentToReplyTo.parentId
        ? comments.find((c) => c._id === commentToReplyTo.parentId)
        : commentToReplyTo;

      const response = await api.post("/comments", {
        content,
        videoId,
        parentId: parentComment._id,
        replyToId: commentId,
        replyToUser: commentToReplyTo.displayName,
      });

      dispatch(
        addReply({
          parentId: parentComment._id,
          reply: response.data,
        })
      );
    } catch (error) {
      console.error("Error adding reply:", error);
    }
  };

  if (loading) return <div>Loading comments...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="mt-6">
      {/* Comments header */}
      <div className="flex items-center mb-6">
        <h3 className="font-roboto text-base">{comments.length} Comments</h3>
        <button className="ml-8 flex items-center gap-2">
          {/* <span className="font-roboto">Sort by</span> */}
        </button>
      </div>

      {/* Add comment section */}
      <AddComment onSubmit={handleAddComment} userImage={userDisplayImage} />

      {/* Comments list */}
      <div className="mt-4 space-y-4">
        {comments.map((comment) => (
          <CommentItem
            key={comment._id}
            comment={comment}
            onReply={handleAddReply}
            onLike={handleLike}
            onDislike={handleDislike}
          />
        ))}
      </div>
    </div>
  );
}

export default Comments;
