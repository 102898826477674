import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PlaylistCard from "../../../components/video/PlaylistCard";
import api from "../../../config/axios";
import { setPlaylists } from "../../../redux/slices/userSlice";

function Playlists() {
  const dispatch = useDispatch();
  const playlists = useSelector((state) => state.user.playlists);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPlaylists();
  }, []);

  const fetchPlaylists = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await api.get("/users/playlists");
      dispatch(setPlaylists(response.data));
    } catch (error) {
      setError(error.message);
      console.error("Failed to fetch playlists:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div className="text-center py-20">Loading playlists...</div>;
  }

  if (error) {
    return <div className="text-center py-20 text-red-600">Error: {error}</div>;
  }

  return (
    <div className="px-8 py-6">
      <h1 className="text-2xl font-bold mb-6">Playlists</h1>

      {playlists.length === 0 ? (
        <div className="text-center py-20">
          <p className="text-gray-600">No playlists yet</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {playlists.map((playlist) => (
            <PlaylistCard key={playlist._id} playlist={playlist} />
          ))}
        </div>
      )}
    </div>
  );
}

export default Playlists;
