import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  videos: [],
  currentVideo: null,
  suggestedVideos: [],
  likedVideos: [],
  isSubscribed: false,
  loading: false,
  error: null,
};

const videoSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setVideos: (state, action) => {
      state.videos = action.payload;
    },
    setCurrentVideo: (state, action) => {
      state.currentVideo = action.payload;
    },
    setSuggestedVideos: (state, action) => {
      state.suggestedVideos = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    updateVideoInteractions: (state, action) => {
      if (state.currentVideo) {
        state.currentVideo = {
          ...state.currentVideo,
          ...action.payload,
        };
      }
    },
    setLikedVideos: (state, action) => {
      state.likedVideos = action.payload;
    },
    setIsSubscribed: (state, action) => {
      state.isSubscribed = action.payload;
    },
  },
});

export const {
  setLoading,
  setVideos,
  setCurrentVideo,
  setSuggestedVideos,
  setError,
  updateVideoInteractions,
  setLikedVideos,
  setIsSubscribed,
} = videoSlice.actions;

export default videoSlice.reducer;
